// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-container {
  height: calc(100vh - 64px);
}

.search-sidenav {
  max-width: 450px;
  min-width: 450px;
  background-color: #fff;
  color: #202020;
}

.position-header {
  position: absolute;
  top: 7px;
  left: 50px;
}

.text {
  margin-right: 10px; /* Adjust margin as needed */
}

.icon {
  font-size: 24px; /* Adjust font size as needed */
  /* Optional: If you want to align the icon vertically with the text */
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
}

.icon-container {
  display: flex;
  align-items: center; /* Vertical alignment */
  justify-content: flex-start; /* Horizontal alignment */
  margin-bottom: 24px;
}

ul.searchlist {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

ul.searchlist li {
  padding: 20px 0px;
}

ul.vida-sidemenu {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.icon-box {
  border: 1px solid #eee;
  background-color: #eaecfb;
  color: #3f51b5;
  padding: 16px;
  border-radius: 100%;
  display: flex;
}

.search-result {
  min-height: 100px;
}

ul.searchlist li {
  border-bottom: 1px solid #e0e0e0;
  background-color: #ffffff;
}

ul.facets-list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.example-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  background: #eceff5;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.position-relative {
  position: relative;
}

.top {
  position: absolute;
  top: 0;
  right: 0;
}

.bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
