import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-shibboleth-dialog',
  templateUrl: './shibboleth-dialog.component.html',
  styleUrls: ['./shibboleth-dialog.component.scss']
})
export class ShibbolethDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ShibbolethDialogComponent>
  ) { }

  ngOnInit() {
  }

}
