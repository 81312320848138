import { Component, OnInit, ElementRef, EventEmitter } from '@angular/core';
import { HttpService } from '../services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QuerystoreService } from '../services/querystore.service';
import { MerklisteService } from '../services/merkliste.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar} from '@angular/material/snack-bar';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



interface DocumentNode {
  id: string;
  titel: string;
  children: DocumentNode[];
}

@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss']
})


export class DocumentViewComponent implements OnInit {

  osdviewer: any;
  osdviewerLoaded: boolean;
  hits: string[];
  boolean: boolean = false;
  message: any;
  document: any;
  checked: boolean;
  prevItem: string;
  nextItem: string;
  isLoadingResults = false;
  result: any[];
  infoRequest: EventEmitter<string> = new EventEmitter<string>();
  metaFromDocument: any;
  kapitellink: any[] = [];
  wordpressPageUrl: string;
  ausgabe: HTMLElement;


// tree
  // dataSource: Document[] = [];
  // treeControl = new NestedTreeControl<Document>(node => node.children);
  fullTree: Document[];
  docId: string;


  treeControl = new NestedTreeControl<DocumentNode>(node => node.children);
  treeDataSource = new MatTreeNestedDataSource<DocumentNode>();
  query: { query: { match: { "117_archivfud": any; }; }; size: number; };

  constructor(
    private http: HttpService,
    private httpClient: HttpClient,
    private el: ElementRef,
    private route: ActivatedRoute,
    public router: Router,
    private hitsStore: QuerystoreService,
    private merklisteService: MerklisteService,
    public snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {
    this.merklisteService.MessageCount$.subscribe((merklisteCount: number) => {
      this.checked = this.merklisteService.hasId(this.route.snapshot.paramMap.get('id'));
    });
  }





  ngOnInit() {


    this.osdviewerLoaded = false;
    this.isLoadingResults = true;
    this.checked = this.merklisteService.hasId(this.route.snapshot.paramMap.get('id'));
    this.http.currentMessage.subscribe(message => this.message = message);
    this.wordpressPageUrl = environment.wordpressPageUrl;
    this.fullTree = [];


    if (this.message.hits) {
      this.message.hits.forEach(element => {
        // console.log(element);
      });
    }

    // SET URL ID
    const id = this.route.snapshot.paramMap.get('id');

    // SET ALL FOUND DOCS
    this.hits = this.hitsStore.getAllHits();

    if (this.hits) {
      for (let i = 0; i < this.hits.length; i++) {
        if (this.hits[i] === id) {
          this.nextItem = this.hits[i + 1];
          this.prevItem = this.hits[i - 1];
        }
      }
    }

    this.http.getDocument(id).subscribe(res => {
      this.document = res;
    });

    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.loadDocument(id);
      }
    });



  }


  private loadDocument(id: string) {
    this.http.getDocument(id).subscribe(res => {
      this.document = res;
      if (this.document['_source']['docTyp']['id'] === '117' || this.document['_source']['docTyp']['id'] === '88') {
        this.fetchTreeData();
      }
      this.isLoadingResults = false;
    });
  }

  fetchTreeData() {
    if(this.document['_source']['docTyp']['id'] == '117') {
      this.query = {
        query: {
          match: {
            "117_archivfud": this.document["_source"]["117_archivfud"]
          }
        }, size: 999
      };
    }

    if(this.document['_source']['docTyp']['id'] == '88') {
      this.query = {
        query: {
          match: {
            "117_archivfud": this.document["_id"]
          }
        }, size: 999
      };
    }

console.log( this.query);
    
    this.http.Search(this.query).subscribe(res => {
      const treeData = this.processTreeData(res.hits.hits, this.document._id);
      this.treeDataSource.data = treeData;
      this.isLoadingResults = false;
    });
  }

  processTreeData(data: any[], startDocId: string): DocumentNode[] {
    let sortedData = this.sortDataAlphanumerically(data, 'ASC');
    let treeData = this.listToTree(sortedData);
    return this.filterTreeForStartDocument(treeData, startDocId);
  }
  
  private filterTreeForStartDocument(tree: DocumentNode[], startDocId: string): DocumentNode[] {
    // Rekursive Funktion zum Filtern des Baums

    if(this.document['_source']['docTyp']['id'] == '88') {
      // dont filter
      return tree;
    } else {
      const filterRecursive = (node: DocumentNode): boolean => {
        if (node.id === startDocId) {
          return true;
        }
        if (node.children && node.children.length > 0) {
          let keepNode = false;
          node.children = node.children.filter(childNode => {
            if (filterRecursive(childNode)) {
              keepNode = true;
              return true;
            }
            return false;
          });
          return keepNode;
        }
        return false;
      };
    
      // Filtern des gesamten Baums
      return tree.filter(node => filterRecursive(node));
    }
  }

  private sortDataAlphanumerically(data: any[], order: 'ASC' | 'DESC'): any[] {
    return data.sort((a, b) => {
      // Get values for 117_signatur
      let aSignatur = a._source['117_signatur'] || ''; // Fallback to empty string if undefined
      let bSignatur = b._source['117_signatur'] || ''; // Fallback to empty string if undefined
  
      // Compare 117_signatur first
      let signaturComparison = order === 'ASC' ? aSignatur.localeCompare(bSignatur) : bSignatur.localeCompare(aSignatur);
  
      // If 117_signatur is the same, then compare 117_titel
      if (signaturComparison === 0) {
        let aTitel = a._source['117_titel'] || ''; // Fallback to empty string if undefined
        let bTitel = b._source['117_titel'] || ''; // Fallback to empty string if undefined
        return order === 'ASC' ? aTitel.localeCompare(bTitel) : bTitel.localeCompare(aTitel);
      }
  
      return signaturComparison;
    });
  }
  

  private listToTree(list: any[]): DocumentNode[] {
    const map = {};
    list.forEach(item => {
     // Check if '117_signatur' exists and use it if it does, otherwise use an empty string or some default value
    let signatur = item["_source"]["117_signatur"] ? item["_source"]["117_signatur"] + ' ' : '';

    // Now concatenate 'signatur' with '117_titel'
    let title = signatur + item["_source"]["117_titel"];

      map[item._id] = {
        id: item._id,
        titel: title,
        children: []
      };
    });
  
    const roots: DocumentNode[] = [];
    list.forEach(item => {
      if (item._source['117_elterndok'] && map[item._source['117_elterndok']]) {
        map[item._source['117_elterndok']].children.push(map[item._id]);
      } else {
        roots.push(map[item._id]);
      }
    });
    console.log(roots);

    return roots;
  }
  

  private filterTree(tree: DocumentNode[], id: string): DocumentNode[] {
    return tree.filter(node => node.id === id || node.children.length > 0);
  }

  hasChild = (_: number, node: DocumentNode) => !!node.children && node.children.length > 0;








  
  // Methode zur asynchronen Abfrage von Kinderdokumenten
  getChildDocuments(parentId: string): Observable<any[]> {
    const query = {
      query: {
        match: {
          "117_elterndok": parentId
        }
      },
      size: 9999
    };
  
    return this.http.Search(query).pipe(
      map(res => res.hits.hits) // Konvertieren Sie die Antwort in das gewünschte Format
    );
  }





// Function to fetch document by ID from Elasticsearch
getDocumentByID(id: string): any {
  return this.http.getDocument(id);
}





  public reload() {
    location.reload();
  }


  public toDocument(id) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/document/' + id]));
  }

  prev() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/document/' + this.prevItem]));
  }

  next() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/document/' + this.nextItem]));
  }

  getMetaFromDocument(doctypeId, id, field) {
    const proxyBody = {
      'query': {
        'bool': {
          'must': [
            {
              'match_phrase': {
                'docTyp.id': doctypeId
              }
            },
            {
              'match_phrase': {
                '_id': id
              }
            }
          ]
        }
      },
      'fields': [
        field
      ],
      '_source': false
    }
    this.httpClient.post(environment.wordpressThemeUrl + environment.documentUrl, proxyBody).subscribe(element => {
      this.metaFromDocument = element;
      this.metaFromDocument = this.metaFromDocument.fields[field][0]
    });
  }


  public onMerklistToggle(event: any, document: any) {
    const snackBarMessage: string = (event.checked) ?
      'Zur Merkliste hinzugefügt' : 'Von Merkliste entfernt';
    this.snackBar.open(snackBarMessage, '', {
      duration: 1000
    });
    console.log(event);
    if (event.checked) {
      this.merklisteService.registerItem(document);
    } else {
      this.merklisteService.unregisterItem(document);
    }
  }



  public addToMerkliste(document: any) {
    this.boolean = !this.boolean;

   if(this.boolean) {
    this.merklisteService.registerItem(document);
   } else {
    this.merklisteService.unregisterItem(document);
   }
    const snackBarMessage: string = (this.boolean) ?
      'Zur Merkliste hinzugefügt' : 'Von Merkliste entfernt';
    this.snackBar.open(snackBarMessage, '', {
      duration: 1000
    });

  }

  printDoc() {
window.print();
    // var mywindow = window.open('', 'PRINT', 'height=800,width=1200');
    // let output = this.ausgabe;
    // mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    // mywindow.document.write('</head><body >');
    // mywindow.document.write('<h1>' + document.title  + '</h1>');
    // mywindow.document.body.appendChild(output);
    // mywindow.document.write('</body></html>');
    // mywindow.document.write('<style type="text/css"media="print">.bold {display: block; font-weight: bold;} .zeile {border-bottom: 1px solid #cac9d2; padding: 20px 0px;}</style>');
    
    // mywindow.document.close(); // necessary for IE >= 10
    // mywindow.focus(); // necessary for IE >= 10*/

    // mywindow.print();
    // mywindow.close();
  }
}