import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HttpService } from '../http.service';
import { Wrapper } from './wrapper';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConverterService{
  private url : string | undefined;

  constructor(
    private httpService : HttpService
  ){
  }

  private getFUD(mapping : FormGroup): string{
    const fud = mapping.get('fud');
    if(!fud){
      console.error('Keine FUD-Version im Mapping angegeben.');
      return '';
    }
    return fud!.value;
  }

  public tryGetActiveIndexFromFields(completeMapping : FormGroup) : string{
    if(completeMapping.get('fud') && completeMapping.get('fuddoctyp')){
      return 'fud_'+completeMapping.get('fud')?.value+'_doctyp'+completeMapping.get('fuddoctyp')?.value;
    }
    else{
      console.error('FUD or Doctyp not given.');
      return '';
    }
  }

  public getWithoutMultiples(array: string[]) : string[]{
    const ret : string[] = [];
    array.forEach((value: string)=>{
      if(ret.indexOf(value)==-1)
        ret.push(value);
    });
    return ret;
  }
  public createHTMLElement(rootnode: HTMLElement, tag: string, id: string) : HTMLElement{
    const el = document.createElement(tag);
    el.id = id;
    rootnode.appendChild(el);
    return el;
  }

  /*
  * Creates a FormGroup from a json object
  * The json object must be surrounded with curly brackets but can contain any depth of arrays inside objects etc.
  */
  public formFromJSON(json: any): FormGroup {
    const fb = new FormBuilder();
    const fg = fb.group({});
    return this.formFromGroup(fg, json, fb) as FormGroup;
  }

  /*
  * If the json object is surrounded with curly brackets, then this function returns the FormGroup for it
  */
  private formFromGroup(parentForm : FormGroup, json: any, fb: FormBuilder){
    for (const key in json) {
      if (Object.prototype.hasOwnProperty.call(json, key)) {
        const value = json[key];
        if (Array.isArray(value)) {
          const formArray : FormArray = fb.array([]);
          this.formFromArray(formArray, value, fb);
          parentForm.addControl(key, formArray);
        } else if (typeof value === 'object' && value !== null) {
          const formGroup : FormGroup = fb.group({});
          parentForm.addControl(key, this.formFromGroup(formGroup, value, fb));
        } else {
          parentForm.addControl(key, new FormControl(value));
        }
      }
    }
    return parentForm;
  }

  /*
  * If the json object is surrounded with squared brackets, then this function returns the FormArray for it
  */
  private formFromArray(parentForm : FormArray, json: any, fb: FormBuilder){
    for (const key in json) {
      if (Object.prototype.hasOwnProperty.call(json, key)) {
        const value = json[key];
  
        if (Array.isArray(value)) {
          const formArray : FormArray = fb.array([]);
          this.formFromArray(formArray, value, fb);
          parentForm.push(formArray);
        } else if (typeof value === 'object' && value !== null) {
          const formGroup : FormGroup = fb.group({});
          parentForm.push(this.formFromGroup(formGroup, value, fb));
        } else {
          parentForm.push(new FormControl(value));
        }
      }
    }
    return parentForm;
  }

  /*
  * Applies the css classes from eclass and bootstrap onto the wrapper of a mapping element
  * 'row' is handled separately because every row needs to be followed by a col element
  */
  public handleEclass(eclass : string[], bootstrap : string, wrapper : HTMLElement | null, children: HTMLElement, parentWrapper?: Wrapper){
    if(!wrapper)
      return;
    
    const bootstrapClasses : string[] = bootstrap?.split(' ');
    if(bootstrapClasses){
      for(const cl of bootstrapClasses){
        wrapper.classList.add(cl);
      }
    }
    
    if(!eclass || eclass == undefined){
      return;
    }
    
    if(eclass.indexOf('row') != -1){
      children.classList.add('row');
    }
    
    // default
    for(const cl of eclass){
      if(cl === 'row'){
        wrapper.classList.add('list');
      }
      else if(cl?.length > 0){
        wrapper.classList.add(cl);
      }
    }
    
    if(parentWrapper?.hasClass('list')){
      wrapper.classList.add('row-item');
      if(eclass.includes('col-md-2') || eclass.includes('col-md-4') || eclass.includes('col-md-6') || eclass.includes('col-md-8') || eclass.includes('col-md-10')){
        // already added to wrapper in default
      } else {
        wrapper.classList.add('col-md-12');
      }
    }
  }

  public handleEpartPrePostClass(eclass : any, html: HTMLElement){
    if(!html)
      return;
    if(!eclass || eclass == undefined){
      return;
    }
    
    const classes : string[] = eclass;    
    // default
    for(const cl of classes){
      if(cl.length > 0){
        html.classList.add(cl);
      }
    }
  }

  public handleElabelClass(eclass : any, label: HTMLElement, content: HTMLElement){
    if(!eclass || eclass == undefined){
      this.labelDefault(label, content);
      return;
    }
    
    const classes : string[] = eclass;
    if(classes.indexOf('above') != -1){ // place label above
      label.classList.add('col-md-12');
      content.classList.add('col-md-12');
    } else{  // default or class nextTo
      this.labelDefault(label, content);
    }
    // default
    for(const cl of classes){
      label.classList.add(cl);
    }
  }

  private labelDefault(label : HTMLElement, content : HTMLElement){
    label.classList.add('col-md-2');
    content.classList.add('col-md-10');
  }

  public get(fc: FormControl, key: string) : any{
    if(fc){
      for(const [k, v] of Object.entries(fc)){
        if(k == key){
          return v;
        }
      }
    }
  }
  private checkIn(value: string, array: string[]){
    for(let i = 0; i < array.length; i++){
      if(array[i]==value)
        return true;
    }
    return false;
  }

  /*
  Ab hier sind die Funktionen nur noch für Doctyp-Suche etc., also nur für Links nötig. Es sind zum Teil Duplikate der Funktionen aus dem MappingTool
  */

  /*
  The first time this function is called, it calculates the URL for all doctyps from the given fud from the mapping and stores it in this service
  When calling it multiple times, it just returns the already calculated URL
  */
  public async getURL(mapping: FormGroup) : Promise<string> {
    const fud = this.getFUD(mapping);
    if(this.url){
      return this.url;
    } else {
      const indices : string[] = await this.getDoctypIndicesForFUD(fud);
      let urlAllDoctyps = '';
      indices.forEach((item, i)=>{
        urlAllDoctyps += item;
        if(i !== indices.length-1){
          urlAllDoctyps += ',';
        }
      });
      console.log('create url', urlAllDoctyps);
      this.url = urlAllDoctyps;
      return urlAllDoctyps;
    }
  }

  /*
  returns all the doctyp indices for the given fud version
  for example: demko -> [fud_demko_doctyp1, fud_demko_doctyp2, ...]
  */
  private async getDoctypIndicesForFUD(fudVersion: string) : Promise<string[]>{
    const allIndices : string[] = [];
    return new Promise<string[]>((resolve, reject)=>{
      this.httpService.getElasticIndices().then((indices: string[]) => {
        indices.forEach((index: string) => {
          if(index !== '.geoip_databases' && !index.includes('analyse')){            
            allIndices.push(index);
          }
        });
        if(allIndices.length <= 0){
          console.error('Could not fetch elastic indices.');
          reject();
        }
        const ret : string[] = [];
        for(const doctyp of allIndices){
          if(this.getFUDVersion(doctyp) == fudVersion)
            ret.push(doctyp);
        }
        resolve(ret);
      });
    });
  }
  
  /*
  for example: 'fud_demko_doctyp1' -> 'demko'
  */
  private getFUDVersion(index : string) : string{
    if(index == undefined){
      console.warn('FUD-Version nicht definiert.');
      return '';
    }
    let ret : string;
    let regex = /fud_/;
    ret = index.replace(regex,'');
    regex = /_doctyp[0-9]*/;
    ret = ret.replace(regex,'');
    regex = /_analyse[\w]*/;
    ret = ret.replace(regex,'');
    return ret;
  }
}