export const environment = {
  production: true,
  documentUrl: '/assets/api/elastic-document.php',
  searchAnalyses: '/assets/api/elastic-searchAnalyse.php',
  searchDocuments: '/assets/api/elastic-searchDocument.php',
  analyseUrl: '/assets/api/elastic-analyse.php',
  wordpressThemeUrl: '/wp-content/themes/understrap-child/app',
  getIndices: '/assets/api/elastic-getIndices.php',
  wordpressPageUrl: '/app'
};
