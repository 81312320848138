// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-element {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 10px;
}

.heading {
  border-bottom: 0px;
}

.small-font {
  font-size: small;
}

.medium-font {
  font-size: medium;
}

#copy-content .label {
  flex-basis: 0;
  flex-grow: 1;
  color: #757575;
}

.custom-converter .heading {
  margin-bottom: 50px;
}

.separator {
  display: block;
  margin-top: 50px;
  color: #023064;
  width: 100%;
}

.wrap-element {
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
