import { SearchSettings } from '../../typings/search-settings';

export const FretSearchSettings: SearchSettings = {
    aggs: [
        {
            id: 'dokumenttyp',
            title: 'Dokumenttyp',
            facetKeys: [
                'facet_doctyp'
            ]
        },
        {
            id: 'archives',
            title: 'Dokumente pro Archiv',
            facetKeys: [
                'facet_archives'
            ]
        },

       
        {
            id: 'VERZEICHNISSTUFE',
            title: 'Verzeichnisstufe der Archivdokumente',
            facetKeys: [
                'facet_typ',
            ]
        },
        {
            id: 'sachindex',
            title: 'Sachindex',
            facetKeys: [
                'facet_keywords',
            ]
        },
        {
            id: 'orte',
            title: 'Ort',
            facetKeys: [
                'facet_locations',
            ]
        },
        {
            id: 'personen',
            title: 'Personen / Organisation',
            facetKeys: [
                'facet_persons',

            ]
        },
        {
            id: 'objektlisten',
            title: 'Objektlisten',
            facetKeys: [
                'facet_listen_vorh',

            ]
        },
        {
            id: 'anhang',
            title: 'Anhang',
            facetKeys: [
                'facet_digitalisat_vorh',

            ]
        },
        
     
       
       
    ],
    validDocTypes: ['_doc']
};
