import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../services/http.service';
import { FudElasticQuery } from '../../typings/fud-elastic-query';
import { QueryBuilderService } from '../services/query-builder.service';
import { FretSearchSettings } from '../config/fret-search-settings';
import { SearchSettings, FacetSetting } from '../../typings/search-settings';
import { Category } from './text-search/text-search.component';
import { Sorting } from './text-search/text-search.component';
import { FilterEvent } from './filter/filter.event';
import { FilterComponent } from './filter/filter.component';
import { Operator } from './text-search/text-search.component';
import { QuerystoreService } from '../services/querystore.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { DrawerService } from '../services/drawer.service';
import { Observable } from 'rxjs';
// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/

import {MatFormFieldModule} from '@angular/material/form-field';

export interface FacetResult {
  key: string;
  doc_count: number;
  selected: boolean;
}


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  mobileQuery: MediaQueryList;

  searchSettings: SearchSettings = FretSearchSettings;

  selectedCategory: Category;
  selectedSorting: Sorting;
  selectedOperator: Operator;

  facetDict: any = {};
  // will map facet ids (e.g. '134_facet_location') to
  // the keyword ('location') by iterating over the facetKeys
  facetIdsToId: any = {};

  // (uses object notation, for each facetId a list of selected topics)
  selectedTopics = {};

  isLoadingResults = false;

  @ViewChild(FilterComponent) filter;


  public documents: Document[] = [];


  // MatPaginator Output
  pageSize: any = 25;
  pageIndex: any = 0;
  lowValue: any = '0';
  count: number;
  highValue: any = '50';
  pageSizeOptions: number[] = [5, 10, 25, 100];
  currentYear = new Date().getUTCFullYear(); // 2020
  date_von_year: any;
  date_bis_year: any;

  date_von: any;
  date_bis: any;


  queryText: string;
  isMobile: boolean;
  firstload: boolean;
  dates: any;
  length: number;
  sort: boolean = false;  // initialize this to false for alphabetic sorting
  sortname: string = 'Alphabet'; // set initial sort name to 'Alphabet'

  drawerState$: Observable<boolean>;
  facets: any;
  facet_stadt: any;
  facet_person: any;
  isActive: boolean;
  min: any;
  max: any;
  isDrawerOpen: boolean;


  constructor(

    private http: HttpService,
    private hitsStore: QuerystoreService,
    private queryBuilder: QueryBuilderService,
    private router: Router,
    private media: MediaMatcher,
    private drawerService: DrawerService

  ) {
    this.mobileQuery = media.matchMedia('(min-width: 769px)');
    this.count = this.length;
    this.facets = '';
    this.isLargeScreen();
    this.drawerState$ = this.drawerService.drawerState$;

 
    
  }

  ngOnInit() {

    this.http.getMinMax().subscribe(
      (response: any) => {

        
        const dateObjMin = new Date(response.aggregations.min_range_geburt.value_as_string);
        this.min = dateObjMin.getUTCFullYear();

  
        const dateObjMax = new Date(response.aggregations.max_range_geburt.value_as_string);
        this.max = dateObjMax.getUTCFullYear();
        this.date_von_year = this.min;
        this.date_bis_year = this.max;
        
        console.log('minMax', this.min, this.max);
    });

// Set the default value for selectedSorting
this.queryBuilder.setFirstload(this.firstload = true);
if (this.queryBuilder.getSort() !== undefined) {
  console.log(this.selectedSorting);
  this.selectedSorting = this.queryBuilder.getSort();
} 



    this.searchSettings.aggs.forEach((facet: FacetSetting) => {
      this.facets = facet;
      // populate facetDict used to pass information to filter boxes
      this.facetDict[facet.id] = {
        'keys': facet.facetKeys,
        'values': [],
        'selectedTerms': []
      };




      // Map all facet keys to the id (to check for existence on return)
      facet.facetKeys.forEach((facetKey: string) => {
        this.facetIdsToId[facetKey] = facet.id;
      });
    });


    if (this.queryBuilder.getTopics() !== undefined) {
      this.selectedTopics = this.queryBuilder.getTopics();
    }

    if (this.queryBuilder.getTerms() !== undefined) {
      this.facetDict = this.queryBuilder.getTerms();
    }

    if (this.queryBuilder.getDates() !== undefined) {
      this.dates = this.queryBuilder.getDates();
      this.date_von_year = this.dates[0];
      this.date_bis_year = this.dates[1];
    }

  
    this.queryBuilder.setSettings(this.searchSettings);
    this.submitSearch();

  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',')
      .map(str => +str);

  }


  // getting selected Facets
  onSelectionChange(event: FilterEvent) {
    this.selectedTopics[event.type] = event.topics;
    this.facetDict[event.type]['selectedTerms'] = event.selectedTerms;

    this.queryBuilder.setTopics(this.selectedTopics);
    this.queryBuilder.setTerms(this.facetDict);

    this.submitSearch();
  }

// Funktion, die bei Änderungen ausgeführt wird
onChange() {
  if (this.isActive) {
    this.submitSearch();
  }
}

submitRange() {
  if (!this.isActive) {
    this.queryBuilder.setDates(this.date_von_year,this.date_bis_year, true);
  } else {
    this.queryBuilder.setDates(this.date_von_year,this.date_bis_year, false);
  }
  console.log(this.isActive);
  this.isActive = !this.isActive;
  this.submitSearch();
}



  getPaginatorData(event) {

    if (event.pageIndex === this.pageIndex + 1) {
      this.lowValue = this.lowValue + event.pageSize;
      this.highValue = this.highValue + event.pageSize;
    } else if (event.pageIndex === this.pageIndex - 1) {
      this.lowValue = this.lowValue - event.pageSize;
      this.highValue = this.highValue - event.pageSize;
    }
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.submitSearch();
  }


  // TODO Bester weg für den Facet reset
  resetFacets() {
    this.filter.aggs.active = [];
    this.submitSearch();
  }

  public onInfoRequest(documentId: string) {
    this.router.navigate(['document', documentId]);
  }

  public onTextSearch(searchText: any) {
    this.queryText = searchText.queryText;
    this.selectedCategory = searchText.category;
    this.selectedSorting = searchText.sorting;
    this.selectedOperator = searchText.operator;
    this.submitSearch();
  }


  submitSearch(event?) {
    this.queryBuilder.setDates(this.date_von_year, this.date_bis_year, this.isActive);


    const query: FudElasticQuery = this.queryBuilder.buildQuery(
      this.queryText,
      this.selectedCategory,
      this.selectedSorting,
      this.selectedOperator,
      this.date_von,
      this.date_bis,
      this.selectedTopics,
      this.pageSize,
      this.pageIndex
    );

    console.log(query);

    this.http.Search(query).subscribe(

      (response: any) => {

          // TODO: necessary? used to rebuild data-table?
          // could possibly use a refresh method
          if (response) {
            this.isLoadingResults = true;
            this.documents = response.hits.hits;
            this.count = response.hits.total.value;
          }
          this.pageIndex = this.pageIndex;
          this.pageSize = this.pageSize;
          this.length = this.length;

    
       

          if (!response.aggregations) {
            return false;
          }

          // temporary object to collect all information
          // to avoid triggering change-detection too often
          const tmpFacets = {};
          let currId: string;
          // tslint:disable-next-line:forin
          for (const facetId in response.aggregations) {
            currId = this.facetIdsToId[facetId];
            if (currId !== undefined) {
              if (tmpFacets[currId] === undefined) {
                tmpFacets[currId] = [];
              }
              tmpFacets[currId] = tmpFacets[currId].concat(
                response.aggregations[facetId].buckets
              );
            }
          }
          // tslint:disable-next-line:forin
          for (const facetId in tmpFacets) {
            tmpFacets[facetId] = this._reduceFacets(
              tmpFacets[facetId], facetId);
          }
          // Now actually assign them to the facetDict (triggering change detection)
          // tslint:disable-next-line:forin
          for (const facetId in tmpFacets) {
            this.facetDict[facetId].values = tmpFacets[facetId];
          }
          
          console.log(response);
          //   this.facet_stadt = response?.aggregations?.facet_stadt?.buckets;
          //   this.facet_person = response?.aggregations?.facet_person?.buckets

          //   this.facet_stadt = this.facet_stadt.filter(item => item.key !== '');
          //   this.facet_person = this.facet_person.filter(item => item.key !== '');

          // console.log(this.facet_person, this.facet_stadt)
  
          this.http.changeMessage(response.hits);
          this.hitsStore.gettingHits(response.hits.hits);


          this.isLoadingResults = false;
        
      }, (error: any) => {
        console.error(error);
      });

    this.queryBuilder.setFirstload(this.firstload = false);
  }

  /**
   * Reduce all facets by setting defaults and adding up values
   */
  private _reduceFacets(aggs: FacetResult[], keywordId: string): { active: FacetResult[], inactive: FacetResult[] } {
    // using reducer on all terms
    const mergedFacets = {};
    aggs.forEach((aggs: FacetResult) => {
      aggs.doc_count = aggs.doc_count || 0;
      if (mergedFacets[aggs.key] === undefined) {
        mergedFacets[aggs.key] = Object.assign(aggs, {});
      } else {
        mergedFacets[aggs.key].doc_count = mergedFacets[aggs.key].doc_count + aggs.doc_count;
      }
    });
    const activeFacets: FacetResult[] = [];
    const inactiveFacets: FacetResult[] = [];
    Object.keys(mergedFacets).forEach((facetKey: string) => {
      if (this.facetDict[keywordId].selectedTerms.indexOf(facetKey) > -1) {
        activeFacets.push(mergedFacets[facetKey]);
      } else {
        inactiveFacets.push(mergedFacets[facetKey]);
      }
    });
  
    // Sort facets alphabetically by default
    activeFacets.sort((a, b) => a.key.localeCompare(b.key));
    inactiveFacets.sort((a, b) => a.key.localeCompare(b.key));
  
    return {
      active: activeFacets,
      inactive: inactiveFacets
    };
  }


  onPrint() {
    window.print();
  }



  resetSearch() {
    window.open('/app/', '_self', '');
  }

  isLargeScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width > 720) {
      return true;
    } else {
      return false;
    }
  }

  sortChange() {
    this.sort = !this.sort;
    let value;
    for (value of Object.values(this.facetDict)) {
      console.log(value);
      if (value && value.values) {
        if (this.sort === false) {
          if (value.values && value.values.active) {
              value.values.active.sort((a, b) => a.key.localeCompare(b.key));
          }
          if (value.values && value.values.inactive) {
              value.values.inactive.sort((a, b) => a.key.localeCompare(b.key));
          }
          this.sortname = 'Alphabet';
      } else {
          if (value.values && value.values.active) {
              value.values.active.sort(function (b, a) { return a.doc_count - b.doc_count; });
          }
          if (value.values && value.values.inactive) {
              value.values.inactive.sort(function (b, a) { return a.doc_count - b.doc_count; });
          }
          this.sortname = 'Anzahl';
      }
      }
    }
  }

  toggleDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen;

    this.drawerService.toggleDrawer();
  }

}
