import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { DocumentViewComponent } from './document-view/document-view.component';
import { SearchComponent } from './search/search.component';
import { FilterComponent } from './search/filter/filter.component';
import { HeaderComponent } from './header/header.component';

// Angular Material Components
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';

// import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
// import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
// import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
// import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
// import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import {MatSelectModule} from '@angular/material/select';
import {MatDividerModule} from '@angular/material/divider';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCardModule} from '@angular/material/card';

import { MatBadgeModule } from '@angular/material/badge';
// import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
// import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
// import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatGridListModule } from '@angular/material/grid-list';
// import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatStepperModule } from '@angular/material/stepper';
// import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
// import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import {LegacyProgressSpinnerMode as ProgressSpinnerMode, MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
// import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
// import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule, MatFormFieldControl} from '@angular/material/form-field';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
// import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
// import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
// import {MatLegacyPaginatorModule as MatPaginatorModule, MatLegacyPaginatorIntl as MatPaginatorIntl} from '@angular/material/legacy-paginator';
// import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';

// Dialog
import { MerklisteDialogComponent} from './merkliste-dialog/merkliste-dialog.component';
import { ShibbolethDialogComponent} from './shibboleth-dialog/shibboleth-dialog.component';

// Third Party
// import { MarkdownToHtmlModule } from 'markdown-to-html-pipe';
import { TextSearchComponent } from './search/text-search/text-search.component';
import { ListItemComponent } from './list-item/list-item.component';

// Language
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { getGermanPaginatorIntl } from './german-paginator-intl';
registerLocaleData(localeDe, 'de-DE', localeDeExtra);

import { AnnotationComponent } from './document-view/annotation/annotation.component';
import { ConverterModule } from './document-view/converter-submodule/converter.module';
import { TreeviewComponent } from './search/treeview/treeview.component';



@NgModule({
    declarations: [
        AppComponent,
        DocumentViewComponent,
        SearchComponent,
        FilterComponent,
        HeaderComponent,
        TextSearchComponent,
        ListItemComponent,
        // Dialog
        MerklisteDialogComponent,
        ShibbolethDialogComponent,
        AnnotationComponent,
        TreeviewComponent,
        
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatCheckboxModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatBadgeModule,
        MatDividerModule,
        // MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatSidenavModule,
        MatToolbarModule,
        // MatListModule,
        MatGridListModule,
        MatCardModule,
        MatStepperModule,
        // MatTabsModule,
        MatExpansionModule,
        MatButtonToggleModule,
        // MatChipsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        // MatProgressBarModule,
        MatDialogModule,
        // MatTooltipModule,
        MatSnackBarModule,
        // MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTreeModule,
        ConverterModule,
        MatProgressSpinnerModule
    ],
    providers: [
        // {
        //     // provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl()
        // },
        {
            provide: LOCALE_ID,
            useValue: 'de-DE'
        }
    ],
    // LESSON-PETER: Hier waren die app-merkliste-snackbar und der
    // Dialog drin. i.d.R. wird nur eine einzige (root)-Komponente
    // gebootstrapped. die anderen sachen (v.a. dialogs) die während
    // der runtime generiert werden kommen in entry-components
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
