import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FilterEvent } from './filter.event';
import { HttpService } from 'src/app/services/http.service';
import { QueryBuilderService } from 'src/app/services/query-builder.service';



@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  // From Parent
  @Input()
  aggs: any;

  @Input()
  isLoadingResults: boolean;

  @Input()
  documents: Document[] = [];

  @Input()
  type: string;

  @Input()
  facetKeys: string[];

  @Input()
  terms: any;



  public show = false;
  buttonName = 'Mehr anzeigen';
  anzahl = '10';

  // Sending my facets to Parent
  @Output()
  selectionChange = new EventEmitter<FilterEvent>();

  // used to build the facet query for the query service
  selectedTopics = {};
  selectedOptions = {};

  // Keep track of the selected Terms
  selectedTerms: string[] = [];
  keys = [];

  savedCheckStatus: any;


  constructor(
    // LESSON-PETER: s.u.
    public http: HttpService,
    public queryBuilder: QueryBuilderService
  ) {

  }

  ngOnInit() {
      this.selectedTerms = this.terms[this.type].selectedTerms;
  }



  onChange(value: string, checked: boolean) {



      // send array to check state service
    if (checked) {
      // add to selected Terms (selectedTerms is delegated to parent to keep track
      //    of active selection)
      console.log('before = ' + this.selectedTerms);
      this.selectedTerms.push(value);
      console.log('after = ' + this.selectedTerms);
      // build the matchphrase for the query
      let currMatchPhrase: any;
      this.selectedTopics[value] = [];
      this.facetKeys.forEach((key: string) => {
        currMatchPhrase = {};
        currMatchPhrase[key] = value;
        this.selectedTopics[value].push({
          'match_phrase': currMatchPhrase
        });

      });
    } else {
        this.selectedTerms = this.selectedTerms.filter((term: any) => {
        return term !== value;
      });
      delete this.selectedTopics[value];
    }



    this.selectionChange.emit({
      topics: this.selectedTopics,
      selectedTerms: this.selectedTerms,
      type: this.type
    });


  }



  toggleAmount() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if ( this.show ) {
      this.buttonName = 'Weniger Anzeigen';
      this.anzahl = '999';
    } else {
      this.buttonName = 'Mehr anzeigen';
      this.anzahl = '10';
    }
  }









}
