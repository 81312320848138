// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.example-tree-invisible {
  display: none;
}

.example-tree ul,
.example-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.mat-tree-node {
  border-bottom: 1px solid #e0e0e0;
}

.mat-tree a, .mat-tree p {
  padding: 12px;
  margin: 0;
}

/*
 * This padding sets alignment of the nested nodes.
 */
.example-tree .mat-nested-tree-node div[role=group] {
  padding-left: 25px;
}

/*
 * Padding for leaf nodes.
 * Leaf nodes need to have padding so as to align with other non-leaf nodes
 * under the same parent.
 */
.example-tree div[role=group] > .mat-tree-node {
  padding-left: 25px;
}

.mat-tree-node {
  min-height: 25px;
}

.example-tree .mat-icon-button {
  padding: 0;
  min-width: 0;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  line-height: 25px;
  border-radius: 50%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
