import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ConverterService } from '../utilities/converter.service';
import { PlaceholderDirective } from '../utilities/placeholder.directive';
import { Wrapper } from '../utilities/wrapper';
import { MappingElementWithFUD, LinkService } from '../link.service';
import { Router } from '@angular/router';
import { query, route } from '../config';
import { DateService } from '../utilities/date.service';

@Component({
  selector: 'app-subcontent',
  templateUrl: './subcontent.component.html',
  styleUrls: ['./subcontent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubcontentComponent implements OnInit, AfterViewInit {

  @Input() representedMappingElement : any;
  @Input() exampleElement: any;
  @Input() id! : string;
  @Input() wrapper! : Wrapper;
  @Input() mappingJSON! : JSON;
  @Input() childCount! : number;
  @Input() urlAllDoctyps! : string;
  @Input() isLastBlockElement! : boolean;
  @Input() mTitles! : string[] | null;
  @Input() kTitles! : string[] | null;
  /*
   * the following inputs are only given in the case that this is the subcontent of a linkcontent
   */
  @Input() linkFromOtherDocument! : boolean | undefined;
  @Input() linkTarget! : string | undefined;
  @Input() linkFudIndex! : string | undefined;
  @Input() linkExtern! : boolean | undefined;
  
  @ViewChild(PlaceholderDirective) placeholder! : PlaceholderDirective;
  @ViewChild('content') content! : ElementRef<HTMLSpanElement>;
  @ViewChild('subcontent') subcontent! : ElementRef<HTMLSpanElement>;
  @ViewChild('pre') prefix! : ElementRef<HTMLSpanElement>;
  @ViewChild('post') postfix! : ElementRef<HTMLSpanElement>;
  @ViewChild('label') label! : ElementRef<HTMLSpanElement>;

  isLink : boolean = false;

  constructor(
    private linkService : LinkService,
    private converterService : ConverterService,
    private dateService : DateService,
    private router : Router,
    private componentRef : ElementRef // reference to this components html element
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.init();
  }

  init() {
    switch(this.representedMappingElement.typ){
    case 'file':
    case 'virtual_file':
      this.handleFile();
      break;
    case 'all_files':
      this.handleAllFiles('k');
      break;
    case 'all_images':
      this.handleAllFiles('m');
      break;
    case 'group':
      this.handleGroup();
      break;
    case 'string':
      this.handleString();
      break;
    case 'link_string':
    case 'link_fud':
      this.handleLinkSubcontent();
      break;
    case 'link_group':
      this.handleLinkSubcontentGroup();
      break;
    default:
      switch(this.representedMappingElement.etype){      
      case 'block':
        this.handleBlock();
        break;
      case 'child':
        this.handleChild();
        break;
      case 'json':
        this.handleJSON();
        break;
      case 'anhang':
        // is now typ file, virtual_file
        break;
      case 'htmltext':
        this.handleHTMLtext();
        break;
      case 'linkgroup':
        this.handleLinkgroup();
        break;
      case 'linkcontent':
        this.handleLinkcontent();
        break;
      case '': // in Feldern ohne FUD-Feld-Eintrag (unterste Ebene)
        this.handleJSON();
        break;
      default:
        this.handleGroup();
        break;
      }
      break;
    }
    
  }

  /*
    idea : iterate over all fields and if they contain a file then create a new subcontent component that handles the file
  */
  handleAllFiles(field : 'm' | 'k') {
    const entries = Object.entries(this.exampleElement);
    let length = 0;
    const mappingElements : any[] = [];
    const exampleElements : string[] = [];
    let mappingChildInformation = this.representedMappingElement?.subcontent;
    if(mappingChildInformation){mappingChildInformation = mappingChildInformation[0];}
    
    for(const [k,v] of entries){
      if((field === 'm' && k.includes('_sourcename')) || (field === 'k' && k.includes('_attachment'))){
        mappingElements.push({
          typ : 'file',
          epart : k,
          epartpre : mappingChildInformation.epartpre,
          epartpost : mappingChildInformation.epartpost,
          eclass : mappingChildInformation.eclass
        });
        exampleElements.push(v as string);
        length++;
      }
    }
    const wrapper = this.handleBoxing(length);
    mappingElements.forEach((mappingElement, index) => {
      const elRef = this.placeholder.viewContainerRef.createComponent(SubcontentComponent);
      elRef.instance.representedMappingElement = mappingElement;
      elRef.instance.id = this.id+' '+index;
      elRef.instance.exampleElement = exampleElements[index];
      elRef.instance.wrapper = wrapper;
      elRef.instance.childCount = length;
      elRef.instance.isLastBlockElement = index == length-1;
      elRef.instance.kTitles = this.kTitles;
      elRef.instance.mTitles = this.mTitles;
    });    
  }

  handleFile() {
    let title: string;
    let filename : string;
    if(this.representedMappingElement.fudfeldtyp === 'kf' || this.representedMappingElement.fudfeldtyp === 'mf'){
      filename = this.exampleElement.filename;
      title = this.exampleElement.sourcename;
    } else {
      filename = this.exampleElement;
      title = this.getTitle();
    }
    
    if(this.representedMappingElement.eclass?.includes('file-image') // standard mapping
      || this.representedMappingElement.display === 'image') // in links the display option
    { // show file as image (image in img tag, file in iframe)
      if(this.representedMappingElement.epart.includes('_sourcename') || this.representedMappingElement.fudfeldtyp === 'mf'){
        this.subcontent.nativeElement.classList.add('link-content-span');
        const image = this.converterService.createHTMLElement(this.subcontent.nativeElement,'img','');
        const eclass : string[] = this.representedMappingElement.eclass;        
        if(eclass.includes('image-original')){
          image.setAttribute('src', 'assets/vmimg/'+filename);
        } else if (eclass.includes('image-thb')){
          image.setAttribute('src', 'assets/vmimg/thb/thb_'+filename);
        } else { // image-mid or nothing
          image.setAttribute('src', 'assets/vmimg/mid/mid_'+filename);
        }
        image.setAttribute('alt', title);
      } else {
        this.subcontent.nativeElement.classList.add('link-content-span');
        const iframe = this.converterService.createHTMLElement(this.subcontent.nativeElement,'iframe','') as HTMLIFrameElement;
        iframe.setAttribute('src', 'assets/vmimg/anhang/'+filename);
        iframe.setAttribute('alt', title);
      }
    } else { // show file as link
      this.isLink = true;
      setTimeout(()=>{
        const anchor = this.content as ElementRef<HTMLAnchorElement>;
        anchor.nativeElement.setAttribute('href', 'assets/vmimg/anhang/'+filename);
        this.subcontent.nativeElement.innerHTML = title;
        this.subcontent.nativeElement.classList.add('link-content-span');
      });
    }
    // timeout because change to anchor tag needs it
    setTimeout(()=>{
      const wrapper = this.handleBoxing(0);
      this.styleSpan(this.subcontent.nativeElement, wrapper);
    });
  }

  // get the title of m or k fields
  private getTitle() {
    const field = this.representedMappingElement.epart as string;
    let attNr: number = 0;
    let isKelseM = true;
    if (field.includes('_attachment')) {
      const index = field.indexOf('_attachment') + '_attachment'.length;
      attNr = Number.parseInt(field.substring(index));
    } else if (field.includes('_sourcename')) {
      isKelseM = false;
      const index = field.indexOf('_sourcename') + '_sourcename'.length;
      attNr = Number.parseInt(field.substring(index));
    }
    let str: string = '';
    if (this.exampleElement != undefined) {
      if (isKelseM) {
        if (this.kTitles) {
          str += this.kTitles[attNr];
        }
      } else {
        if (this.mTitles) {
          str += this.mTitles[attNr];
        }
      }
      if (str.length <= 0) { // default : if no title is given just display the file name as title
        str += this.exampleElement;
      }
    }
    return str;
  }

  handleHTMLtext() {
    const wrapper = this.handleBoxing(0);
    if(!this.exampleElement){
      return;
    }
    if(this.exampleElement.length > 0){
      this.subcontent.nativeElement.innerHTML = this.exampleElement;
    }
    this.subcontent.nativeElement.classList.add('html-text-span');
    
    // handle ::externallink class
    let modifiedText = this.exampleElement as string;
    const linkRegex = /<[^>]*class="externallink::(.*?)">[^<]*<\/[^>]*>/g;
    const matches = modifiedText.match(linkRegex);
    if (matches) {
      for(const match of matches){
        const subRegex = /<[^>]*class="externallink::(.*?)">(.*?)<\/[^>]*>/;
        const parts = match.match(subRegex);
        if(parts){
          const element = parts[0];
          const url = parts[1];
          const linkText = parts[2];
          modifiedText = modifiedText.replace(element, `<a href="${url}" target="_blank">${linkText}</a>`);
        }
      }
      this.subcontent.nativeElement.innerHTML = modifiedText;
    }
    this.styleSpan(this.subcontent.nativeElement, wrapper);
  }

  handleString(){
    const wrapper = this.handleBoxing(0);
    const content = this.representedMappingElement.epart;
    if(!content)
      return;
    let str : string = '';
    str+=content;
    this.subcontent.nativeElement.innerHTML = str;
    this.subcontent.nativeElement.classList.add('string-span');
    this.styleSpan(this.subcontent.nativeElement, wrapper);
  }


  handleJSON() {    
    const wrapper = this.handleBoxing(0);
    const content = this.exampleElement;
    if(!content)
      return;
    let str : string = '';
    if(this.exampleElement != undefined){
      str += content;
    }
    else{
      str += '';
    }
    if(this.representedMappingElement.usefunction === 'dateformat'){
      str = this.dateService.formatDate(str,
        this.representedMappingElement.subcontent[0].epart,
        this.representedMappingElement.subcontent[0].elang);
    }
    this.subcontent.nativeElement.innerHTML = str;
    this.subcontent.nativeElement.classList.add('json-span');
    this.styleSpan(this.subcontent.nativeElement, wrapper);
  }

  styleSpan(span : HTMLElement, wrapper : Wrapper){
    if(wrapper.ancestorOrSelfHasClass('fudvalue')){
      span.style.fontWeight = '200';
    }
    if(wrapper.ancestorOrSelfHasClass('fudvaluehighlight')){
      span.style.fontWeight = '200';
      span.style.backgroundColor = '#EACF57';
    }
  }

  async handleLinkgroup() {
    let target : MappingElementWithFUD | null = null;
    let content : MappingElementWithFUD | null = null;
    let contentFromOtherDocument : boolean = false;
    const parts = Object.values(this.representedMappingElement.subcontent);
    parts.forEach((linkpart : any)=>{ // ein Durchlauf für target, einer für content
      if(linkpart.etype == 'linktarget'){
        // linktarget
        target = this.linkService.getTarget(
          linkpart.subcontent[0],
          this.exampleElement
        );
      } else {
        // linkcontent
        content = linkpart;
        contentFromOtherDocument = linkpart.fromOtherDocument;
      }
    });
    if(!target && contentFromOtherDocument){
      // field is not present in the document and no default display possible
      // if the content is in the same document, only the content without link will be displayed

      this.wrapper.oneChildMoreReady(); // make parent wrapper ready anyways
      return;
    }
    target = target as unknown as MappingElementWithFUD; // needed because the compiler is stupid

    const wrapper = this.handleBoxing(1);
    
    const elRef = this.placeholder.viewContainerRef.createComponent(SubcontentComponent);
    elRef.instance.representedMappingElement = content;
    elRef.instance.id = this.id + ' (link) ';
    elRef.instance.exampleElement = this.exampleElement;
    elRef.instance.wrapper = wrapper;
    elRef.instance.childCount = 1;
    elRef.instance.isLastBlockElement = this.isLastBlockElement;
    elRef.instance.kTitles = this.kTitles;
    elRef.instance.mTitles = this.mTitles;
    elRef.instance.linkFromOtherDocument = contentFromOtherDocument;
    elRef.instance.linkFudIndex = this.representedMappingElement.subcontent[1].qdoctyp;
    elRef.instance.linkTarget = target ? target.documentValue : undefined;
    elRef.instance.linkExtern = this.representedMappingElement.typ === 'exlink';
  }

  /*
  *   creates a new subcontent element that inserts the link content inside a html element
  *   the html element can be:
  *   anchor tag - for links that should show up as links
  *   span - for links that should show up as images or plain text
  * 
  *   in the case of simple link contents:
  *   the next subcontent component gets all the fake information such that it thinks it creates a normal mapped element
  * 
  *   in the case of link_group:
  *   the next subcontent components get handled by the handleLinkSubcontent()-function
  */
  private async handleLinkcontent() {
    // this next element created below will insert the text inside the anchor-tag
    const wrapper = this.handleBoxing(this.representedMappingElement.subcontent.length);
    
    this.representedMappingElement.subcontent.forEach((linkContent : any, index : number)=>{
      const elRef = this.placeholder.viewContainerRef.createComponent(SubcontentComponent);
      elRef.instance.representedMappingElement = linkContent;
      elRef.instance.id = this.id + ' ' + index;
      elRef.instance.exampleElement = this.exampleElement;
      elRef.instance.wrapper = wrapper;
      elRef.instance.childCount = 1;
      elRef.instance.isLastBlockElement = this.isLastBlockElement;
      elRef.instance.kTitles = this.kTitles;
      elRef.instance.mTitles = this.mTitles;
      elRef.instance.linkFromOtherDocument = this.linkFromOtherDocument;
      elRef.instance.linkFudIndex = this.linkFudIndex;
      elRef.instance.linkTarget = this.linkTarget;
      elRef.instance.linkExtern = this.linkExtern;
    });
  }

  async handleLinkSubcontent(){
    await this.changeToAnchorTagIfNeeded();
    let content;
    if(this.linkFromOtherDocument){
      if(!this.linkFudIndex || !this.linkTarget){
        console.error('Error for fud document: ',this.exampleElement,' and mapping part: ',this.representedMappingElement, ' and fudindex: ',this.linkFudIndex);
        throw new Error('SubcontentComponent with typ:link_fud that represents a inlink from another document expects an fud index and a link target.');
      }
      content = await this.linkService.getContentHttp(
        this.representedMappingElement,
        this.exampleElement,
        this.linkFudIndex,
        this.linkTarget
      );      
    } else {
      content = this.linkService.getContentNoHttp(
        this.representedMappingElement,
        this.exampleElement
      );
    }
    if(!content){
      console.warn('No content could be found for link mapping element ',this.representedMappingElement,' with fud document part: ',this.exampleElement);
      return;
    }
    this.subcontent.nativeElement.innerHTML = content.documentValue;
    this.subcontent.nativeElement.classList.add('link-content-span');

    // must stay behind all the asynchronous function calls because it sends a message that this element is ready
    const wrapper = this.handleBoxing(0);
    this.styleSpan(this.subcontent.nativeElement, wrapper);
  }

  async handleLinkSubcontentGroup(){
    await this.changeToAnchorTagIfNeeded();

    const childCount = this.representedMappingElement.subcontent.length;
    const wrapper = this.handleBoxing(childCount);    

    this.representedMappingElement.subcontent.forEach((mappingSubcontent : any, index : number) => {
      const elRef = this.placeholder.viewContainerRef.createComponent(SubcontentComponent);
      elRef.instance.representedMappingElement = mappingSubcontent;
      elRef.instance.id = this.id + ' ' + index;
      elRef.instance.exampleElement = this.exampleElement;
      elRef.instance.wrapper = wrapper;
      elRef.instance.childCount = childCount;
      elRef.instance.isLastBlockElement = index === childCount -1;
      elRef.instance.kTitles = this.kTitles;
      elRef.instance.mTitles = this.mTitles;
      elRef.instance.linkFromOtherDocument = this.linkFromOtherDocument;
      elRef.instance.linkFudIndex = this.linkFudIndex;
      elRef.instance.linkTarget = this.linkTarget;
      elRef.instance.linkExtern = this.linkExtern;
    });
  }

  private async changeToAnchorTagIfNeeded() : Promise<void>{
    return new Promise((resolve)=>{
      const displayInlinkContentWithoutTarget = !this.linkTarget && !this.linkFromOtherDocument;
      const mapping = this.representedMappingElement;      
      if(displayInlinkContentWithoutTarget || mapping.display === 'text'){
        // no anchor tag
        mapping.etype = 'json';
        mapping.fudfeldtyp = undefined;
      } else {
        if(mapping.display === 'image'){
          mapping.etype = 'anhang';
        } else { // display: link
          setTimeout(()=>this.isLink = true);
          if(mapping.etype === 'anhang'){
            mapping.etype = 'json';
          }
        }
      }
      if(mapping.display === 'text' || !this.linkTarget){
        resolve();
      }
      if(mapping.display === 'link'){
        setTimeout(()=>{
          const content = this.content.nativeElement as HTMLAnchorElement;
          if (!this.linkExtern) {
            content.setAttribute('href', '#/'+route+'/'+ this.linkTarget); // just for display purposes
            content.addEventListener('click', (event : any) => {
              event.preventDefault();
              if (query) {
                this.router.navigate([route], {
                  queryParams: {
                    id: this.linkTarget
                  },
                  queryParamsHandling: 'merge'
                });
              } else {
                this.router.navigate([route + '/' + this.linkTarget]);
              }
            });
          } else {
            content.setAttribute('href', this.linkTarget ? this.linkTarget : '');
            content.setAttribute('target', '_blank'); // open in new tab
          }
          resolve();
        });
      }
    });
  }

  handleChild() {
    const mappingSubcontents = this.representedMappingElement.subcontent;
    const wrapper = this.handleBoxing(mappingSubcontents.length);

    mappingSubcontents.forEach((mappingSubcontent: any, subcontentIndex: number)=>{
      const nextExampleElement = this.converterService.get(this.exampleElement,mappingSubcontent.epart);
      if(nextExampleElement != undefined){
        const elRef = this.placeholder.viewContainerRef.createComponent(SubcontentComponent);
        elRef.instance.representedMappingElement = mappingSubcontent;
        elRef.instance.id = this.id+' '+subcontentIndex;
        elRef.instance.exampleElement = nextExampleElement;
        elRef.instance.wrapper = wrapper;
        elRef.instance.childCount = mappingSubcontents.length;
        elRef.instance.isLastBlockElement = false;
        elRef.instance.kTitles = this.kTitles;
        elRef.instance.mTitles = this.mTitles;
      } else if (mappingSubcontent.etype === 'linkgroup' || mappingSubcontent.typ === 'group'){ // stay with this epart
        const elRef = this.placeholder.viewContainerRef.createComponent(SubcontentComponent);
        elRef.instance.representedMappingElement = mappingSubcontent;
        elRef.instance.id = this.id+' '+subcontentIndex;
        elRef.instance.exampleElement = this.exampleElement;
        elRef.instance.wrapper = wrapper;
        elRef.instance.childCount = mappingSubcontents.length;
        elRef.instance.isLastBlockElement = false;
        elRef.instance.kTitles = this.kTitles;
        elRef.instance.mTitles = this.mTitles;
      } else {
        this.wrapper.oneChildMoreReady(); // if no fud representation can be found, just increase number of ready children on parent wrapper
      }
    });
  }

  handleGroup(){
    const mappingSubcontents = this.representedMappingElement.subcontent;
    const amountOfChildren = mappingSubcontents.length;
    const wrapper = this.handleBoxing(amountOfChildren);
    mappingSubcontents.forEach((mappingSubcontent: any, subcontentIndex: number)=>{
      this.createNextSubcontentComponent(this.exampleElement, mappingSubcontent, subcontentIndex, wrapper, amountOfChildren, this.isLastBlockElement);
    });
  }

  handleBlock() {
    const mappingSubcontents = this.representedMappingElement.subcontent;
    const amountOfChildren = mappingSubcontents.length;
    const wrapper = this.handleBoxing(amountOfChildren);
    
    // wählt nächsten FUD-Eintrag aus dem Beispiel-Dokument. Falls epart leer, siehe nächste for-Schleife
    if(this.representedMappingElement.fudfeldtyp === 'y'){
      let counter = 0;
      this.exampleElement?.forEach((value : string) => {
        mappingSubcontents.forEach((mappingSubcontent: any, subcontentIndex: number)=>{
          if(!this.exampleElement){
            for(let i = 0; i < amountOfChildren; i++){
              const elRef = this.placeholder.viewContainerRef.createComponent(SubcontentComponent);
              elRef.instance.representedMappingElement = mappingSubcontent;
              elRef.instance.id = this.id+' '+subcontentIndex;
              elRef.instance.exampleElement = value;
              elRef.instance.wrapper = wrapper;
              elRef.instance.childCount = this.exampleElement.length;
              elRef.instance.isLastBlockElement = ++counter == this.exampleElement.length;
              elRef.instance.kTitles = this.kTitles;
              elRef.instance.mTitles = this.mTitles;
              this.wrapper.oneChildMoreReady(); // make mapping children ready even when no fud representation exists.
            }
          }
        });
      });
    }
    if(this.exampleElement){
      const entries = Object.entries(this.exampleElement);
      let counter = 0;
      let isLastBlockElement : boolean;      
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for(const [k, v] of entries){
        isLastBlockElement = ++counter == entries.length;
        mappingSubcontents.forEach((mappingSubcontent: any, subcontentIndex: number)=>{
          this.createNextSubcontentComponent(v, mappingSubcontent, subcontentIndex, wrapper, amountOfChildren, isLastBlockElement);
          // make mapping children ready even when no fud representation exists.
          setTimeout(()=>this.wrapper.oneChildMoreReady());
        });
      }
      if(entries.length === 0){
        for(let i = 0; i < amountOfChildren; i++){
          this.wrapper.oneChildMoreReady(); // make mapping children ready even when no fud representation exists.
        }
      }
    }
    if(!this.exampleElement){
      for(let i = 0; i < amountOfChildren; i++){
        this.wrapper.oneChildMoreReady(); // make mapping children ready even when no fud representation exists.
      }
    }
  }

  private createNextSubcontentComponent(v : any, mappingSubcontent : any, subcontentIndex : number, wrapper : Wrapper, mappingsLength : number, isLastBlockElement : boolean){
    const nextExampleElement = this.converterService.get(v as UntypedFormControl,mappingSubcontent.epart);
    if(nextExampleElement != undefined){
      const elRef = this.placeholder.viewContainerRef.createComponent(SubcontentComponent);
      elRef.instance.representedMappingElement = mappingSubcontent;
      elRef.instance.id = this.id+' '+subcontentIndex;
      elRef.instance.exampleElement = nextExampleElement;
      elRef.instance.wrapper = wrapper;
      elRef.instance.childCount = mappingsLength;
      elRef.instance.isLastBlockElement = isLastBlockElement;
      elRef.instance.kTitles = this.kTitles;
      elRef.instance.mTitles = this.mTitles;
    } else if (mappingSubcontent.etype === 'linkgroup'){ // get children as example elements
      const elRef = this.placeholder.viewContainerRef.createComponent(SubcontentComponent);
      elRef.instance.representedMappingElement = mappingSubcontent;
      elRef.instance.id = this.id+' '+subcontentIndex;
      elRef.instance.exampleElement = v;
      elRef.instance.wrapper = wrapper;
      elRef.instance.childCount = mappingsLength;
      elRef.instance.isLastBlockElement = isLastBlockElement;
      elRef.instance.kTitles = this.kTitles;
      elRef.instance.mTitles = this.mTitles;
    } else if (v !== undefined){
      const elRef = this.placeholder.viewContainerRef.createComponent(SubcontentComponent);
      elRef.instance.representedMappingElement = mappingSubcontent;
      elRef.instance.id = this.id+' '+subcontentIndex;
      elRef.instance.exampleElement = v;
      elRef.instance.wrapper = wrapper;
      elRef.instance.childCount = mappingsLength;
      elRef.instance.isLastBlockElement = isLastBlockElement;
      elRef.instance.kTitles = this.kTitles;
      elRef.instance.mTitles = this.mTitles;
    } else {
      for(let i = 0; i < mappingsLength; i++){
        this.wrapper.oneChildMoreReady(); // make mapping children ready even when no fud representation exists.
      }
    }
  }

  getMappingElementFromLinkContentToHandleEclass() : any | undefined {
    const linkgroup = this.representedMappingElement.subcontent[0];
    if(!linkgroup || linkgroup.etype !== 'linkgroup')
      return undefined;
    let linkcontent;
    linkgroup.subcontent.forEach((part : any)=>{
      if(part.etype === 'linkcontent'){
        linkcontent = part;
      }
    });
    if(!linkcontent)
      return undefined;
    return linkcontent;
  }

  /*
   *  if representedMappingElement is not given, the class variable with the same name is used
  */
  private handleBoxing(amountOfChildren : number) : Wrapper{
    const wrapper = this.createWrapper(amountOfChildren);
    const wrap = wrapper.htmlWrapper;
    const subcontent = wrapper.subcontentDiv;
    const label = wrapper.labelSpan;
    if(!this.representedMappingElement.eclass){
      if(this.wrapper?.hasClass('list')){
        const arr : string[] = [];
        this.representedMappingElement.eclass = arr;
        // if the parent wrapper has a list attribute we need an eclass array to handle the list children.
      }
    }
    this.converterService.handleEclass(
      this.representedMappingElement.eclass,
      this.representedMappingElement.bootstrap,
      wrap,
      subcontent,
      this.wrapper);
    if(wrap){
      if(wrapper.hasClass('labelobj')){
        wrap.style.width = '20%';
        wrap.style.float = 'left';
        if(wrapper.parentWrapper?.htmlWrapper)
          wrapper.parentWrapper!.htmlWrapper.style.overflow = 'auto';
        label.style.width = '0%';
      }
      if(wrapper.hasClass('80percent')){
        wrap.style.width = '80%';
      }
    }
    return wrapper;
  }

  createWrapper(amountOfChildren : number) : Wrapper{
    const label = this.createLabel();
    this.prePost();
    const contentDiv = this.content.nativeElement;
    const subcontentDiv = this.subcontent.nativeElement;
    if(!this.componentRef.nativeElement){
      console.log(this.representedMappingElement);
      
    }
    const wrapper = new Wrapper(this.componentRef.nativeElement, this.wrapper, label, contentDiv, subcontentDiv, amountOfChildren, this.representedMappingElement);
    if (label.innerHTML == '') {
      //contentDiv.classList.add("col-md-12");
    }
    else {
      label.classList.add('col-md-2');
      label.style.float = 'left';
      contentDiv.classList.add('col-md-10');
      contentDiv.style.float = 'right';
    }
    return wrapper;
  }

  createLabel() : HTMLElement{
    const html = this.label.nativeElement;
    let labelstring = '';
    if(this.representedMappingElement.elabel){
      this.representedMappingElement.elabel.forEach((label : any) => {
        if(label.labelstring){
          labelstring = label.labelstring;
        }
        html.innerHTML = labelstring;
        if(label.eclass){
          this.converterService.handleEpartPrePostClass(label.eclass, html);
        }
        if(label.lang){
          // TODO language handling
        }
      });
    }
    return html;
  }

  prePost(){
    const prefixSpan = this.prefix.nativeElement;
    const prefix = this.representedMappingElement.epartpre;
    if(prefix){
      if(prefix.epartpre){
        prefixSpan.innerHTML = prefix.epartpre;
        this.converterService.handleEpartPrePostClass(prefix.eclass, prefixSpan);
      }
    }
    const postfixSpan = this.postfix.nativeElement;
    const postfix = this.representedMappingElement.epartpost;
    if(postfix){
      if(postfix.epartpost){
        if(this.isLastBlockElement && postfix.eclass !== undefined && postfix.eclass.includes('without-last')){
          // leave out epartpost
        } else{
          postfixSpan.innerHTML = postfix.epartpost;
        }
        this.converterService.handleEpartPrePostClass(postfix.eclass, postfixSpan);
      }
    }
  }
}
