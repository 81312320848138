// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-box {
  border: 1px solid #eee;
  background-color: #eaecfb;
  color: #37347e;
  padding: 16px;
  border-radius: 100%;
  display: flex;
}

.pre-font {
  color: #000;
  font-size: 1rem;
}

.position-icon {
  background-color: #d8ddea;
  border-radius: 100px;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

img.traffic-icon {
  width: 18px;
  height: 30px;
  transform: rotate(90deg);
  left: 21px;
  position: absolute;
  bottom: -7px;
}

.position-relative {
  position: relative;
}

.top {
  position: absolute;
  top: 0;
  right: 0;
}

.bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}

.list-item-thumb {
  width: 150px;
  height: 100%;
}

a.zwischenablage {
  color: #df692e;
  cursor: pointer;
}

.text-beschr span {
  float: left;
  opacity: 0.5;
  max-width: 120px;
}

.flex-fixed-width-item {
  flex: 0 0 185px;
  padding-right: 15px;
  padding-left: 15px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
