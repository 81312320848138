import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService{  
  constructor(){}

  /*
    * Formats the date string according to the given format string in the given language.
    * 
    * formatDate('1949-09-30', 'ddd, d.m.yyyy', 'de-DE') results in 'Fr, 30.9.1949'
    * 
    * possible format string parts: 'd', 'dd', 'ddd', 'dddd', 'm', 'mm', 'mmm', 'mmmm', 'yy', 'yyyy'
    * all other parts of the format string will be copied to the result.
    * the parts in between will not appear if the previous date part is not present
    */
  public formatDate(date : string, format : string, lang : string) : string {
    const regex = /(\w+|\/|\s|\.|,|-)/g;
    format = format.toLowerCase();
    const formatParts = format.match(regex);
    const dateParts = date.split('-');
    const day = dateParts[2];
    const month = dateParts[1];
    const year = dateParts[0];
    const d = new Date(date);
    let result = '';    
    formatParts?.forEach((formatPart, i) => {
      switch(formatPart){
      case 'd':
        if(day){
          result += d.getDate();
        }
        break;
      case 'dd':
        if(day){
          result += day;
        }
        break;
      case 'ddd':
        if(day){
          result += this.getDayOfWeek(d.getDay(), lang, true);
        }
        break;
      case 'dddd':
        if(day){
          result += this.getDayOfWeek(d.getDay(), lang, false);
        }        
        break;
      case 'm':
        if(month){
          result += d.getMonth();
        }
        break;
      case 'mm':
        if(month){
          result += month;
        }
        break;
      case 'mmm':
        if(month){
          result += this.getMonth(+month, lang, true);
        }
        break;
      case 'mmmm':
        if(month){
          result += this.getMonth(+month, lang, false);
        }
        break;
      case 'yy':
        if(year){
          result += year.substring(2);
        }
        break;
      case 'yyyy':
        if(year){
          result += year;
        }
        break;
      default: {
        /*  If last formatPart that represents a day, month, year is NOT PRESENT, the dividing part should not be displayed
            *   For example: ddd, d.m.yyyy and only the year '1999' is present, then the displayed value should be '1999' and not ', ..1999'
            *   That's why the following logic is needed.
            */
        const lastDay = this.getLastIndexIncluding(formatParts, i-1, 'd');
        const lastMonth = this.getLastIndexIncluding(formatParts, i-1, 'm');
        const lastYear = this.getLastIndexIncluding(formatParts, i-1, 'y');
        const max = Math.max(lastDay, lastMonth, lastYear);
        if(max <= -1){
          result += formatPart;
          break;
        }
        if(lastDay === max && !day){
          break;
        }
        if(lastMonth === max && !month){
          break;
        }
        if(lastYear === max && !year){
          break;
        }
        result += formatPart;
      }
      }
    });
    return result;
  }
  
  /*
    * Starting at the given index, this function looks through the previous indices looking for the search string
    * Returns the first index where the search string can be found or -1 if it is not present
    * getLastIndexIncluding(['ab','c','ab','d','e','a'], 4, 'a') would return 2
    */
  private getLastIndexIncluding(arr : string[], index : number, search : string) : number{
    for(let i = index; i>=0; i--){
      if(arr[i].includes(search)){
        return i;
      }
    }
    return -1;
  }
  
  private getDayOfWeek(day : number, lang : string, abbreviation : boolean) : string{
    if(lang === 'de-DE'){
      if(day === 1){
        if(abbreviation){
          return 'Mo';
        }
        return 'Montag';
      } else if (day === 2){
        if(abbreviation){
          return 'Di';
        }
        return 'Dienstag';
      } else if (day === 3){
        if(abbreviation){
          return 'Mi';
        }
        return 'Mittwoch';
      } else if (day === 4){
        if(abbreviation){
          return 'Do';
        }
        return 'Donnerstag';
      } else if (day === 5){
        if(abbreviation){
          return 'Fr';
        }
        return 'Freitag';
      } else if (day === 6){
        if(abbreviation){
          return 'Sa';
        }
        return 'Samstag';
      } else if (day === 0){ // getDay offers a 0 for sunday, don't know why
        if(abbreviation){
          return 'So';
        }
        return 'Sonntag';
      }
    }
    return 'NO TRANSLATION';
  }
  
  private getMonth(month : number, lang : string, abbreviation : boolean) : string{
    if(lang === 'de-DE'){
      if(month === 1){
        if(abbreviation){
          return 'Jan';
        }
        return 'Januar';
      } else if (month === 2){
        if(abbreviation){
          return 'Feb';
        }
        return 'Februar';
      } else if (month === 3){
        if(abbreviation){
          return 'Mär';
        }
        return 'März';
      } else if (month === 4){
        if(abbreviation){
          return 'Apr';
        }
        return 'April';
      } else if (month === 5){
        return 'Mai';
      } else if (month === 6){
        if(abbreviation){
          return 'Jun';
        }
        return 'Juni';
      } else if (month === 7){
        if(abbreviation){
          return 'Jul';
        }
        return 'Juli';
      } else if (month === 8){
        if(abbreviation){
          return 'Aug';
        }
        return 'August';
      } else if (month === 9){
        if(abbreviation){
          return 'Sep';
        }
        return 'September';
      } else if (month === 10){
        if(abbreviation){
          return 'Okt';
        }
        return 'Oktober';
      } else if (month === 11){
        if(abbreviation){
          return 'Nov';
        }
        return 'November';
      } else if (month === 12){
        if(abbreviation){
          return 'Dez';
        }
        return 'Dezember';
      }
    }
    return 'NO TRANSLATION';
  }
}