import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { MatDialogRef } from '@angular/material/dialog';

import { MerklisteService } from '../services/merkliste.service';


@Component({
  selector: 'app-merkliste-dialog',
  templateUrl: './merkliste-dialog.component.html',
  styleUrls: ['./merkliste-dialog.component.scss']
})
export class MerklisteDialogComponent implements OnInit {

  documents: any[];

  constructor(
    private merklisteService: MerklisteService,
    private router: Router,
    public dialogRef: MatDialogRef<MerklisteDialogComponent>
    ) {
      this.merklisteService.MessageCount$.subscribe((merklisteCount: number) => {
        this.documents = this.merklisteService.getAllObjects();
      });
    }

  ngOnInit() {
    this.documents = this.merklisteService.getAllObjects();
    console.log(this.documents);
  }

  public onInfoRequest(documentId: string) {
    console.log(documentId);
    this.router.navigateByUrl('/' , {skipLocationChange: true}).then(() =>
    this.router.navigate(['/document/' + documentId]));

    this.dialogRef.close();
  }

  printDoc() {
    window.print();
  }

  printDiv() {
      const divContents = document.getElementById('GFG').innerHTML;

      const mywindow = window.open('', 'new div', 'height=400,width=600');
      mywindow.document.write('<html><head><title></title>');
      mywindow.document.write('<link rel="stylesheet" href="/assets/css/print.css" type="text/css" />');
      mywindow.document.write('</head><body >');
      mywindow.document.write(divContents);
      mywindow.document.write('</body></html>');
      mywindow.document.close();
      mywindow.focus();
      setTimeout(function() {mywindow.print(); }, 1000);

  }
}
