/* eslint-disable @typescript-eslint/no-this-alias */
const debug = false;

export class Wrapper{
  private children : Wrapper[];
  private childrenReady : number = 0;
  constructor(
            public htmlWrapper:HTMLElement|null,
            public parentWrapper:Wrapper|null,
            public labelSpan : HTMLElement,
            public contentDiv : HTMLElement,
            public subcontentDiv : HTMLElement,
            public totalChildren : number,
            private representedMappingElement : any
  ){
    this.children = [];
    parentWrapper?.addChild(this);
    if(this.totalChildren === 0){
      parentWrapper?.oneChildMoreReady();
    }
  }

  private deadlockCounter = 0;

  public async onReady() : Promise<any>{
    while(this.childrenReady < this.totalChildren){
      console.log(this.childrenReady, ' of total ', this.totalChildren, ' ready!');
      await new Promise(f => setTimeout(f, 100));
      if(this.deadlockCounter++ > 10){
        break;
      }
    }
    if(this.childrenReady < this.totalChildren){
      console.error('Deletion of empty elements seems to be stuck because some children have not been marked as ready...');
      this.printIfError();
    }
    console.log('Start removal of empty elements.');
    return new Promise((resolve:any)=>{
      resolve();
    });
  }

  public printIfError(){
    if(this.childrenReady < this.totalChildren){
      console.error('Children wrapper not ready for ',this, ' with represented mapping element: ',this.representedMappingElement);
      for(const child of this.children){
        child.printIfError();
      }
    }
  }

  public getChildren(){
    return this.children;
  }

  public oneChildMoreReady(){
    this.childrenReady++;
    if(this.childrenReady >= this.totalChildren){
      this.parentWrapper?.oneChildMoreReady();
    }
  }

  private addChild(wrapper : Wrapper){
    this.children.push(wrapper);
  }

  public getRootWrapper() : Wrapper{
    let currentWrapper : Wrapper | null = this;
    while(currentWrapper?.parentWrapper){
      currentWrapper = currentWrapper.parentWrapper;
    }
    return currentWrapper;
  }

  /*
        returns true if deleted itself because it was empty
    */
  public async deleteIfEmpty() : Promise<boolean>{
    if(!this.htmlWrapper){
      return true;
    }

    let allWrapChildrenEmpty = true;
    for(const child of this.children){
      const deletedChild = await child.deleteIfEmpty();
      if(!deletedChild){
        allWrapChildrenEmpty = false;
      }
    }
    if(allWrapChildrenEmpty){
      for (const toIgnore of ['json-span', 'string-span', 'html-text-span', 'link-content-span']){
        if(this.subcontentDiv.classList.contains(toIgnore)){
          return false;
        }
      }            
      const childrenArray = Array.prototype.slice.call(this.subcontentDiv.children);
      for(const childHTML of childrenArray){
        // children must contain images if all empty subwrappers are deleted already
        if(childHTML instanceof HTMLImageElement || childHTML instanceof HTMLIFrameElement){
          return false;
        }
      }

      // Use a Promise to ensure the htmlWrapper is removed after children are processed
      if(debug){
        this.htmlWrapper.classList.add('removed');
      } else {
        return new Promise<boolean>((resolve) => {
          // Create a MutationObserver to watch for the removal of the htmlWrapper
          const observer = new MutationObserver(() => {
            observer.disconnect(); // Disconnect the observer after the mutation is observed
                        
            resolve(true);
          });
                    
          // TODO: Why do some htmlWrapper have no parentElement?
          if(this.htmlWrapper?.parentElement){
            // Start observing the htmlWrapper for removal
            observer.observe(this.htmlWrapper.parentElement, {childList : true});
                        
                        this.htmlWrapper!.remove();
          } else {
            // in case the htmlWrapper has no parent we cannot observe when it's being removed.
                        
            this.htmlWrapper?.remove();
            resolve(true);
          }
        });
      }
    }
    return false;
  }

  public hasClass(cl : string) : boolean{
    if(!this.htmlWrapper){
      console.warn('has no html wrapper: ',this.representedMappingElement);
      return false;
    }
    if(this.htmlWrapper.classList.contains(cl))
      return true;
    return false;
  }

  public getClasses() : string{
    if(!this.htmlWrapper)
      return '';
    return this.htmlWrapper.classList.toString();
  }

  public ancestorHasClass(cl : string) : boolean{
    if(!this.htmlWrapper)
      return false;
    let currentWrapper : Wrapper | null = this.parentWrapper;
    while(currentWrapper){
      if(!currentWrapper.htmlWrapper)
        continue;
      if(currentWrapper.htmlWrapper.classList.contains(cl))
        return true;
      currentWrapper = currentWrapper.parentWrapper;
    }
    return false;
  }

  public getAncestorClasses() : string{
    let classes : string = '';
    let currentWrapper : Wrapper | null = this.parentWrapper;
    while(currentWrapper){
      if(!currentWrapper.htmlWrapper){
        continue;
      }
      classes += currentWrapper.htmlWrapper.classList.toString();
      currentWrapper = currentWrapper.parentWrapper;
    }
    return classes;
  }

  public ancestorOrSelfHasClass(cl : string) : boolean{
    let currentWrapper : Wrapper | null = this;
    while(currentWrapper){
      if(!currentWrapper.htmlWrapper){
        continue;
      }
      if(currentWrapper.htmlWrapper.classList.contains(cl))
        return true;
      currentWrapper = currentWrapper.parentWrapper;
    }
    return false;
  }

  public getAncestorOrSelfClasses() : string{
    let classes : string = '';
    let currentWrapper : Wrapper | null = this;
    while(currentWrapper){
      if(!currentWrapper.htmlWrapper){
        continue;
      }
      classes += currentWrapper.htmlWrapper.classList.toString();
      currentWrapper = currentWrapper.parentWrapper;
    }
    return classes;
  }
}