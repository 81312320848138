import { Component, OnChanges, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';

import { MerklisteService } from '../services/merkliste.service';
import { HttpService } from '../services/http.service';


@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit, OnChanges {

  @Input()
  document: any;



  @Output()
  infoRequest: EventEmitter<string> = new EventEmitter<string>();


  zwischenablage: any;

  checked: boolean;
  placesStandort_aktuell = [];
  placesVerortung = [];
  placesKirchenkreis = [];
  getNewParentID = '';
  i: number;
  docTitle2: any;
  wordpressPageUrl: string;
  signatur: any;
  truncatedText: any;
  truncatedTextPerson: string;

  constructor(
    private merklisteService: MerklisteService,
    public snackBar: MatSnackBar,
    public http: HttpService
  ) {
    this.merklisteService.MessageCount$.subscribe((merklisteCount: number) => {
      this.checked = this.merklisteService.hasId(this.document['_source']['ID']);
    });


  }


  ngOnInit() {
    this.wordpressPageUrl = environment.wordpressPageUrl;
    if (this.document && this.document['_source']) {


      if (this.document['_source']['ID']) {
        this.checked = this.merklisteService.hasId(this.document['_source']['ID']);
      }

      if(this.document && this.document['_source'] && this.document['_source'].docTyp && this.document['_source'].docTyp.id == '81') {

        if (this.document['_source']['81_aufbewahrungsort']) {
          this.http.getDocument(this.document['_source']['81_aufbewahrungsort']).subscribe(res => {

            this.signatur = '<a href="' + this.wordpressPageUrl + '/#/document/' + res['_source']['ID'] + '">' + res['_source']['88_signatur'] + '</a>, ' + this.document['_source']['81_bestsig'];
          })
        }
      }

      if(this.document && this.document['_source'] && this.document['_source'].docTyp && this.document['_source'].docTyp.id == '82') {

        if (this.document['_source']['82_archivbestand']) {
          this.http.getDocument(this.document['_source']['82_archivbestand']).subscribe(res81 => {
            this.http.getDocument(res81['_source']['81_aufbewahrungsort']).subscribe(res88 => {
              this.signatur = '<a href="' + this.wordpressPageUrl + '/#/document/' + res88['_source']['ID'] + '">' + res88['_source']['88_signatur'] + '</a>, ' 
              + '<a href="' + this.wordpressPageUrl + '/#/document/' + res81['_source']['ID'] + '">' + res81['_source']['81_bestsig'] + '</a>'
              + ', ' + this.document['_source']['82_arve_sig'];

            });
          })
        }
      }




    }

    if (this.document['_source']['119_html']) {
      this.truncatedText = this.truncateText(this.document['_source']['119_html'], 300, '...');
    }

    if (this.document['_source']['44_html']) {
      this.truncatedTextPerson = this.truncateText(this.document['_source']['44_html'], 300, '...');
    }
  }


  truncateText(text: string, limit: number, trail: string): string {
    if (text.length > limit) {
      return text.substring(0, limit) + trail;
    } else {
      return text;
    }
  }

  ngOnChanges(changes: any) {
    // console.log(changes);
  }



  public onMerklistToggle(event: any, document: any) {
    const snackBarMessage: string = (event.checked) ?
      'Zur Merkliste hinzugefügt' : 'Von Merkliste entfernt';
    this.snackBar.open(snackBarMessage, '', {
      duration: 1000
    });
    if (event.checked) {
      this.merklisteService.registerItem(document);
    } else {
      this.merklisteService.unregisterItem(document);
    }
  }



  public onInfoClick() {
    this.infoRequest.emit(this.document['_source']['ID']);
  }

}
