// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hochschule {
  background-color: #efefef;
  position: relative;
  list-style: none;
}

ul.hochschule-list {
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
