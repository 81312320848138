import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {

  constructor() { }
  private drawerSubject = new BehaviorSubject(true);
  drawerState$ = this.drawerSubject.asObservable();

  toggleDrawer() {
    this.drawerSubject.next(!this.drawerSubject.value);
  }
}
