// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.blue-box {
  border: 2px solid #3f50b5;
  color: #3e50b5;
  box-shadow: none;
}

.scroll-me {
  max-width: 750px;
  margin-left: auto;
  padding-top: 60px;
  margin-right: auto;
}

:host ::ng-deep .ebene1 {
  padding-left: 10px;
}

:host ::ng-deep .ebene2 {
  padding-left: 20px;
}

:host ::ng-deep .ebene3 {
  padding-left: 30px;
}

:host ::ng-deep .ebene4 {
  padding-left: 40px;
}

:host ::ng-deep .ebene5 {
  padding-left: 50px;
}

:host ::ng-deep .ebene6 {
  padding-left: 60px;
}

:host ::ng-deep .ebene7 {
  padding-left: 70px;
}

:host ::ng-deep .ebene8 {
  padding-left: 80px;
}

:host ::ng-deep.prskapitälchen {
  font-variant: small-caps;
}

:host ::ng-deep.prsheadline1 {
  font-size: 2rem;
  line-height: 2.5rem;
  margin-top: 20px;
  margin-bottom: 12px;
  font-weight: 700;
}

:host ::ng-deep.prsheadline2 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 20px;
  margin-bottom: 12px;
  font-weight: 700;
}

:host ::ng-deep.prsheadline3 {
  font-size: 1.3rem;
  line-height: 1.5rem;
  margin-top: 20px;
  margin-bottom: 12px;
  font-weight: 700;
}

:host ::ng-deep.prsheadline4 {
  font-size: 1.2rem;
  line-height: 1.3rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 12px;
  font-weight: 700;
}

:host ::ng-deep.prsheadline5 {
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 12px;
  font-weight: 700;
}

:host ::ng-deep.weight-bold {
  font-weight: bold;
}

:host ::ng-deep br {
  margin-bottom: 50px;
}

:host ::ng-deep.underline-1 {
  text-decoration: underline;
}

.circle-raum {
  background-color: #89fcf7;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
}

.circle-notice {
  background-color: #80ff80;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
}

.circle-person {
  background-color: #80ff80;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
}

.circle-text {
  background-color: #bebe7c;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
}

.circle-textkom {
  background-color: #efe206;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
}

.circle-sachkom {
  background-color: #4a3aff;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
}

.circle-sach {
  background-color: #ffaa82;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: absolute;
}

:host ::ng-deep .highlight-index {
  color: #000;
  background-color: #ffaa82;
  outline: 2px solid #ffaa82;
}

:host ::ng-deep .highlight-raum {
  color: #000;
  background-color: #89fcf7;
  outline: 2px solid #89fcf7;
}

:host ::ng-deep .html-box a {
  position: relative;
}

:host ::ng-deep .html-box a .sup {
  position: absolute;
  top: -10px;
}

:host ::ng-deep .highlight-person {
  color: #000;
  background-color: #80ff80;
  outline: 2px solid #80ff80;
}

:host ::ng-deep .highlight-notice {
  color: #fff;
  background-color: #80ff80;
  outline: 2px solid #80ff80;
}

:host ::ng-deep .highlight-text {
  color: #000;
  background-color: #bebe7c;
  outline: 2px solid #bebe7c;
}

:host ::ng-deep .highlight-textkom {
  color: #000;
  background-color: #efe206;
  outline: 2px solid #efe206;
}

.highlight-text-chip {
  background-color: #bebe7c;
}

:host ::ng-deep .highlight-sach {
  color: #000;
  background-color: #ffff80;
  outline: 2px solid #ffff80;
}

:host ::ng-deep .highlight-sachkom {
  color: #000;
  background-color: #4a3aff;
  outline: 2px solid #4a3aff;
}

.highlight-sach-chip {
  background-color: #ffff80;
}

a:active, a:focus, a:target {
  background-color: #dfe3ff;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 0 10px;
}

:host ::ng-deep .underline-1 {
  text-decoration: underline;
}

:host ::ng-deep .slant-italic {
  font-style: italic;
}

:host ::ng-deep .offset-4 {
  vertical-align: super;
}

:host ::ng-deep .offset--4 {
  vertical-align: sub;
}

:host ::ng-deep .overstrike-1 {
  text-decoration: line-through;
}

:host ::ng-deep .weight-bold {
  font-weight: bold;
}

:host ::ng-deep .justify-left {
  text-align: left;
}

:host ::ng-deep .justify-center {
  text-align: center;
}

:host ::ng-deep .justify-right {
  text-align: right;
}

:host ::ng-deep.binde-wort {
  color: #707070;
  font-family: merriweather;
  font-style: italic;
  font-size: 1rem;
}

:host ::ng-deep.br {
  display: block;
}

.linked-keyword {
  cursor: pointer;
  padding-bottom: 10px;
  display: block;
}

.subzeile::after {
  content: "\\a";
  white-space: pre;
}

:host ::ng-deep.highlighted {
  background-color: #dfe3ff;
}

.html-box {
  overflow: auto;
  height: 80vh;
  margin-top: 20px;
  padding: 20px;
  font-family: serif;
  background-color: #fff;
}

.kommentar-box {
  overflow: auto;
  height: 80vh;
  margin-top: 20px;
  padding: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
