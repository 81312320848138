// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-container {
  height: calc(100% - 64px);
}

.search-sidenav {
  max-width: 200px;
}

.foto-subline {
  font-size: 0.7rem;
}

.mat-tree {
  background: #fff;
  border-left: 5px solid #023064;
}

.navigator {
  width: 100%;
  z-index: 999;
  top: 80px;
  color: #fff;
  background: #023063;
  border-bottom: 1px solid #e8ebfc;
}

.sublabel {
  display: block;
  font-weight: bold;
}

.mat-mdc-button.mat-unthemed {
  color: #fff;
}

.documentSidenav {
  z-index: 999;
  width: 63px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: absolute;
  right: 8px;
  border-radius: 28px;
  padding: 12px;
}

.document-wrapper {
  height: calc(100% - 64px);
  background-color: #e6e5ef;
}

.heading {
  background-color: #fff;
  position: relative;
  margin-top: 0;
}

ul {
  margin: 0;
  padding-left: 0;
}

.document-headline {
  font-weight: bold;
  margin-bottom: 0;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.info {
  border: 1px solid #3f51b5;
  padding: 20px;
  background-color: #e6e9f7;
  margin-top: 50px;
}

.link {
  color: #3f51b5;
  cursor: pointer;
}

.link:hover {
  color: #3f51b5;
  text-decoration: underline;
}

.thumbnail {
  height: 100px;
  width: 70px;
}

#copy-content li {
  padding-bottom: 10px;
}

h5.separator {
  margin-top: 20px;
  color: #38337c;
  font-weight: bold;
}

.icon-box {
  border: 1px solid #eee;
  background-color: #eaecfb;
  color: #023063;
  padding: 0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
}

@media only screen and (max-width: 600px) {
  .icon-box {
    display: none;
  }
}
.break {
  page-break-after: always;
}

.break img {
  height: 800px;
  width: 600px;
}

.scroll-me {
  height: 800px;
  overflow-y: auto;
  overflow-x: overlay;
  padding: 20px;
}

#output li {
  list-style: none;
}

.pre-font {
  color: #000;
  font-size: 1.6rem;
}

.profil {
  width: 120px;
  margin-top: 60px;
  margin-right: 57px;
  float: left;
  margin-bottom: 20px;
  max-height: 200px;
}

.container-fluid {
  padding-left: 5%;
  padding-right: 5%;
}

.loading {
  background-color: white;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 99999;
  top: 14px;
  margin: 0;
  padding: 0;
  transition: 0.5s;
}

.position-icon {
  font-size: 2.5rem;
  line-height: 1.5rem;
}

.text-beschr {
  font-weight: bold;
  font-size: 20px;
  font-family: "Roboto Condensed", sans-serif;
  margin-bottom: 4px;
}

.document-view-headline {
  font: 400 24px/32px Roboto Condensed;
  margin: 0 0 16px;
  font-size: 35px;
  line-height: 49px;
  margin-bottom: 50px;
  margin-top: 60px;
  color: #0041ff;
}

.text-inhalt {
  padding-left: 0px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .o-first {
    order: -1 !important;
  }
}
.o-first {
  order: 1;
}

.sidenav-detail {
  overflow: hidden;
  height: calc(100vh - 133px);
}

.sticky { /* Safari */
  position: sticky;
  top: 0;
}

.carousel {
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
}

.owl-carousel .owl-stage {
  position: relative;
  touch-action: manipulation;
  height: 300px;
  overflow: hidden;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-item img {
  display: block;
  width: auto;
  max-height: 270px !important;
}

.docopenseadragon {
  height: 800px;
  /* max-width: 500px; */
  background-color: #fff;
  border-radius: 6px;
  padding: 10px;
  margin-top: 20px;
}

.item {
  background-color: #d7d2cc;
  color: #212529;
  padding: 16px;
  height: 300px;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-me {
  justify-content: center;
  /* align-items: center; */
  /* align-content: center; */
  display: flex;
}

.divider {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.detail-input {
  opacity: 0.5;
}

.example-tree-invisible {
  display: none;
}

.example-tree ul,
.example-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.mat-tree-node {
  border-bottom: 1px solid #e0e0e0;
}

.mat-tree a {
  padding: 12px;
}

/*
 * This padding sets alignment of the nested nodes.
 */
.example-tree .mat-nested-tree-node div[role=group] {
  padding-left: 25px;
}

.archives {
  background-color: #fff;
  border-left: 5px solid #023063;
  padding-left: 16px;
  padding-top: 8px;
}

/*
 * Padding for leaf nodes.
 * Leaf nodes need to have padding so as to align with other non-leaf nodes
 * under the same parent.
 */
.example-tree div[role=group] > .mat-tree-node {
  padding-left: 25px;
}

.mat-tree-node {
  min-height: 25px;
  padding-left: 8px;
}

.example-tree .mat-icon-button {
  padding: 0;
  min-width: 0;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  line-height: 25px;
  border-radius: 50%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
