// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul.facets-list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.hide-facet {
  display: none;
}

.capi:first-letter {
  text-transform: capitalize;
}

p.capi {
  margin: 0;
  margin-right: 6px;
  display: block;
}

.mehr {
  margin-top: 16px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
