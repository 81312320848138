/*
* CONVERTER CONFIG
*/

// which are the names of the mappings in src/assets/mappings ?
export const mappingNames: string[] = [
    'mapping117.json',
    'mapping88.json',
    'mapping44.json',
    'mapping119.json'
]

// To which route should the router navigate when using links?
export const route: string = 'document';

// Should links write the id of the document in a query parameter or in a normal route parameter?
export const query: boolean = false;
  

/*
* HTTP CONFIG
*/

// Do you want to use express.js or php?
export const useExpress : boolean = false;

// Where is your projects environment config?
import { environment as stdEnv}  from 'src/environments/environment';
export const standardEnvironment = stdEnv;

// Where is your projects production environment config?
import { environment as prodEnv } from 'src/environments/environment.prod';
export const productionEnvironment = prodEnv;

// Where is your projects development environment config?
import { environment as devEnv } from 'src/environments/environment.dev';
export const developmentEnvironment = devEnv;
