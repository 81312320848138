import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})

export class HttpService {

  private messageSource = new BehaviorSubject('keine Query');
  currentMessage = this.messageSource.asObservable();
  allIds: any;
  body: any;
  bodyAllIds: any;
  bodySearch: any;

  constructor(
    private http: HttpClient) {
  }


  changeMessage(message: string) {
    this.messageSource.next(message);
  }


  Search(body: any): Observable<any> {
    return this.http.post(environment.wordpressThemeUrl + environment.searchDocuments, body);
  }

  SearchAnalyse(): Observable<any> {
    const body = {
      
        "query": {
          "match": {
            "regtext": "Hierarchie"
          }
        },
        size: 99999
      
    }
    return this.http.post(environment.wordpressThemeUrl + environment.searchAnalyses, body);
  }

  SearchTree(): Observable<any> {
    const body = {
      "query": {
        "match_all": {}
    }, size: 99999
      
    }
    return this.http.post(environment.wordpressThemeUrl + environment.searchDocuments, body);
  }

  getDocument(id: any): any {
    const proxyBody = {
      'query': {
        'multi_match': {
          'query': id,
          'fields': ['_id']
        }
      }
    };
    return this.http.post(environment.wordpressThemeUrl + environment.documentUrl, proxyBody);
  }

  getAnalyse(id: any): any {
    const proxyBody = {
      'query': {
        'multi_match': {
          'query': id,
          'fields': ['_id']
        }
      }
    };
    return this.http.post(environment.wordpressThemeUrl + environment.analyseUrl, proxyBody);
  }

  getMinMax() {
    return this.http.post(environment.wordpressThemeUrl + environment.searchDocuments, {
      "aggs": {
        "min_range_geburt": {
          "min": {
            "field": "117_datestd"
          }
        },
        "max_range_geburt": {
          "max": {
            "field": "117_datestd"
          }
        }
      }
    });
  }

  findABQ(field, id) {

    const proxyBody = {
      "query": {
        "match": { [field]: id }
      },
      size: 999
    };

    console.log(proxyBody)

    return this.http.post(environment.wordpressThemeUrl + environment.searchDocuments, proxyBody)
  }

  getMapping() {
    return this.http.get(environment.wordpressThemeUrl + '/assets/json/mapping.json');
  }
}
