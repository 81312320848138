/**
 * Builds queries by adding and removing facets
 */

import { Injectable } from '@angular/core';
import { FudElasticQuery } from '../../typings/fud-elastic-query';
import { SearchSettings, FacetSetting } from '../../typings/search-settings';
import { SearchFilters } from './searchFilters'; // Der Pfad kann sich ändern, abhängig davon, wo Sie die Schnittstellendatei ablegen



@Injectable({
  providedIn: 'root'
})
export class QueryBuilderService {

  firstload: boolean;
  currQuery: FudElasticQuery;
  facetObj: any;
  validDocTypes: any[] = [];
  sortArray: any[] = [];
  operator: any;
  type: any;
  savedTerms: any;
  savedTopics: any;
  savedSort: any;
  savedCat: any;
  savedOpt: any;
  savedQuerytext: any;
  savedDates: any;
  savedDateVon: any;
  savedDateBis: any;
  dates: any[];
  RangeActive: any;

  constructor(
  ) {

  }

  public setFirstload(firstload) {
    this.firstload = firstload;
  }

  public getFirstload() {
    return this.firstload;
  }

  public setQuery(query: FudElasticQuery) {
    this.currQuery = query;
  }

  public getQuery(): FudElasticQuery {
    return this.currQuery;
  }

  public setTerms(terms) {
    this.savedTerms = terms;
  }

  public getTerms() {
    return this.savedTerms;
  }

  public setTopics(topics) {
    console.log(this.savedTopics);
  }

  public getTopics() {
    return this.savedTopics;
  }

  public setDates(von, bis, state) {
    this.RangeActive = state;
    this.savedDateVon = von;
    this.savedDateBis = bis;
  }

  public getDates() {
    if (this.savedDateVon !== undefined && this.savedDateBis !== undefined) {
      return [this.savedDateVon, this.savedDateBis];
    } else {
      return undefined;
    }
  }



  public setSort(sort) {
    this.savedSort = sort;
  }

  public getSort() {
    return this.savedSort;
  }

  public setQuerytext(Querytext) {
    this.savedQuerytext = Querytext;
  }

  public getQuerytext() {
    return this.savedQuerytext;
  }

  public setCat(Cat) {
    this.savedCat = Cat;
  }

  public getCat() {
    return this.savedCat;
  }

  public setOpt(Opt) {
    this.savedOpt = Opt;
  }

  public getOpt() {
    return this.savedOpt;
  }



  public setSettings(settings: SearchSettings) {
    const currFacetObj = {};
    // build the facet objects to be returned
    // from the query
    settings.aggs.forEach((facetSetting: FacetSetting) => {
      facetSetting.facetKeys.forEach((facetKey: string) => {
        currFacetObj[facetKey] = {
          'terms': {
            'field': facetKey,
            'size': 999
          }
        };
      });
    });
    this.facetObj = currFacetObj;
    settings.validDocTypes.forEach((docType: string) => {
      this.validDocTypes.push({
        'type': {
          'value': docType
        }
      });
    });
  }


  public buildQuery(
    searchText: string,
    selectedCategory: any,
    selectedSorting: any,
    selectedOparator: any,
    date_von: any,
    date_bis: any,
    facetPhrases: any,
    pageSize: number,
    pageIndex: number): FudElasticQuery {



    if (selectedSorting === undefined) {
      selectedSorting = 'asc';
    }
    const should = facetPhrases;
    const topicBooleans = [];
    const range = [];
    // tslint:disable-next-line:forin
    for (const member in facetPhrases) {
      // tslint:disable-next-line:forin
      for (const subMember in facetPhrases[member]) {
        topicBooleans.push({
          'bool': {
            'should': facetPhrases[member][subMember]
          }
        });
      }
    }


if( this.RangeActive) {
if (this.savedDateVon !== undefined && this.savedDateVon !== null && this.savedDateVon !== "" &&
this.savedDateBis !== undefined && this.savedDateBis !== null && this.savedDateBis !== "") {

      this.savedDateVon = new Date(this.savedDateVon + '-01-01');
      this.savedDateBis = new Date(this.savedDateBis + '-12-31');

      topicBooleans.push({
        'bool': {
          'should': [
            {
              'bool': {
                'must': [
                  {
                    'range': {
                      '117_datestd': {
                        'gte': this.savedDateVon
                      }
                    }
                  },
                  {
                    'range': {
                      '117_datestd': {
                        'lte': this.savedDateBis
                      }
                    }
                  }
                ]
              }
            }
          ]
        }
      }
      );
    }
  }



    let textQuery: any;
    if (!searchText) {
      textQuery = { 'match_all': {} };
    } else {

      selectedOparator = selectedOparator;

      if (selectedOparator === 'and') {
        this.operator = 'and';
        this.type = 'cross_fields';
      }else if (selectedOparator === 'or') {
        this.operator = 'or';
        this.type = 'cross_fields';
      } else if (selectedOparator === 'genau') {
        this.type = 'phrase_prefix';
      } else {
        this.type = 'cross_fields';
        console.log('DEFAULT OPERATOR')
      }

      textQuery = {
        'multi_match': {
          'query': searchText,
          'operator': this.operator,
          'type': this.type,
          'fields': selectedCategory
        }
      };
    }
    const must = [
      textQuery,
      {
        'bool': {
          'must': topicBooleans,
        }
      }
    ];



    selectedSorting = JSON.stringify(selectedSorting);
  
    if (selectedSorting === '"relevance"') {
      this.sortArray = [

      ];
    }


    if (selectedSorting === '"asc"') {
      this.sortArray = [
        { 'sort_title': { 'order': 'asc' } }
      ];
    }

    if (selectedSorting === '"desc"') {
      this.sortArray = [
        { 'sort_title': { 'order': 'desc' } }
      ];
    }

    if (selectedSorting === '"signatur"') {
      this.sortArray = [
        { 'signatursort': { 'order': 'asc' } }
      ];
    }

    if (selectedSorting === '"date_ASC"') {
      this.sortArray = [
        { '117_datestd': { 'order': 'asc' } }
      ];
    }

    if (selectedSorting === '"date_DESC"') {
      this.sortArray = [
        { '117_datestd': { 'order': 'desc' } }
      ];
    }


    if (pageIndex === 0) {
      pageIndex = 0;
    } else {
      pageIndex = pageIndex * pageSize;
    }




    // loading from saved Query
    if (this.firstload === true && this.currQuery !== undefined) {
      const query: FudElasticQuery = {
        'size': this.currQuery.size,
        'from': this.currQuery.from,
        'sort': this.currQuery.sort,
        'query': this.currQuery.query,
        'aggs': this.currQuery.aggs,

      };
      this.setQuery(query);
      return query;
    } else {
      const query: FudElasticQuery = {
        'size': pageSize,
        'from': pageIndex,
        'sort': this.sortArray,
        'query': {
          'bool': {
            'must': {
              'bool': {
                'must': must,
              }
            }
          }
        },
        'aggs': this.facetObj,
      };
      this.setQuery(query);
      console.log(query);
      return query;
    }



  }

  private searchFilters: SearchFilters;

  public setFilters(filters: SearchFilters): void {
    this.searchFilters = filters;
  }

  public getFilters(): SearchFilters {
    return this.searchFilters;
  }

}
