import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { NestedTreeControl } from '@angular/cdk/tree';



@Component({
  selector: 'app-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: ['./treeview.component.scss']
})
export class TreeviewComponent implements OnInit {



  constructor(
    private httpService: HttpService
  ){
  }

  dataSource: YourTreeNode[] = []; // Hier sollten Sie Ihren Typ für die Baumknoten anpassen
  treeControl = new NestedTreeControl<YourTreeNode>(node => node.children);
  hasChild = (_: number, node: YourTreeNode) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    this.httpService.SearchAnalyse().subscribe((res: any) => {
      if (res.hits && res.hits.hits) {
        const hits = res.hits.hits;
        this.dataSource = this.convertToTree(hits);
      }
    });
  }

  convertToTree(hits: any[]): YourTreeNode[] {
    const nodesMap = new Map<string, YourTreeNode>();
  
    // Create nodes and store them in a Map
    hits.forEach(hit => {
      const node: YourTreeNode = {
        ID: hit._source.ID,
        link: '',
        content: hit._source.content,
        children: [],
      };
  
      // Check if there is an 'lmdoclink' field in the source data
      if (hit._source.LmAdd && hit._source.LmAdd.lmdoclink) {
        node.link = '/app/#/document/' + hit._source.LmAdd.lmdoclink;
      }
  
      nodesMap.set(hit._source.ID, node);
    });
  
    // Assign parent-child relationships
    hits.forEach(hit => {
      const node = nodesMap.get(hit._source.ID);
      const parentID = hit._source.parentID;
  
      if (parentID && parentID !== "0") {
        const parentNode = nodesMap.get(parentID);
        if (parentNode) {
          parentNode.children.push(node);
        }
      }
    });
  
    // Get the top-level nodes (nodes with parentID 0 or without valid parents in map)
    const topLevelNodes = Array.from(nodesMap.values()).filter(node => {
      const parentID = hits.find(hit => hit._source.ID === node.ID)._source.parentID;
      return parentID === "0" || !nodesMap.has(parentID);
    });
  
    // Optional: Sort top-level nodes if needed
    topLevelNodes.sort((a, b) => a.content.localeCompare(b.content));
  

     // Sortieren der Top-Level-Knoten
  topLevelNodes.forEach(node => this.sortTreeNodes(node));
  return topLevelNodes;
  }



  sortTreeNodes(node: YourTreeNode): void {
    if (node.children && node.children.length > 0) {
      // Sortieren der Kinder alphanumerisch nach 'content'
      node.children.sort((a, b) => a.content.localeCompare(b.content));
  
      // Rekursiver Aufruf für jedes Kind
      node.children.forEach(child => {
        this.sortTreeNodes(child);
      });
    }
  }
  

}

interface YourTreeNode {
  ID: string;
  link: string;
  content: string;
  children: YourTreeNode[];
}



