import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-annotation',
  templateUrl: './annotation.component.html',
  styleUrls: ['./annotation.component.scss']
})
export class AnnotationComponent implements OnInit {

  @Input() document:any;
  sachKommentar: any[] = [];
  textKommentar: any[] = [];
  presets = [
    {
      "preset": "-replacepreset",
      "starttag": "prsparagraph prsaufzebene",
      "endtag": "prsaufzebene",
      "br_remove": "yes",
      "note": "Da Aufzählungsebene und Paragraph sich momentan ins Gehege kommen, wir das am Anfang entfernt. DAS HIER IST EIN replace-Aufruf und KEIN PRESET!"
  },
  {
      "preset": "prsheadline2",
      "starttag": "<h2>",
      "endtag": "</h2>",
      "br_remove": "yes",
      "note": ""
  },
  {
      "preset": "prsheadline3",
      "starttag": "<h3>",
      "endtag": "</h3>",
      "br_remove": "yes",
      "note": ""
  },
  {
      "preset": "prsheadline4",
      "starttag": "<h4>",
      "endtag": "</h4>",
      "br_remove": "yes",
      "note": ""
  },
  {
      "preset": "prsheadline5",
      "starttag": "<h5>",
      "endtag": "</h5>",
      "br_remove": "yes",
      "note": ""
  },
  {
      "preset": "prsheadline6",
      "starttag": "<h6>",
      "endtag": "</h6>",
      "br_remove": "yes",
      "note": ""
  },
  {
      "preset": "prsparagraph",
      "starttag": "<p1>",
      "endtag": "</p1>",
      "br_remove": "yes",
      "note": ""
  },
  {
      "preset": "index-3014",
      "starttag": "<ul class=\"listebene1\">",
      "endtag": "</ul>",
      "br_remove": "no",
      "note": "da durch Import aus Analyse kommt, bedingt dies keinen Zeilenumbruch! (yr)"
  },
  {
      "preset": "index-3015",
      "starttag": "<ul class=\"listebene2\">",
      "endtag": "</ul>",
      "br_remove": "no",
      "note": "s. note Index-3014"
  },
  {
      "preset": "index-3018",
      "starttag": "<ul class=\"listebene3\">",
      "endtag": "</ul>",
      "br_remove": "no",
      "note": "s. note Index-3014"
  },
  {
      "preset": "prsaufzebene1",
      "starttag": "<li class=\"ebene1\">",
      "endtag": "</li>",
      "br_remove": "yes",
      "note": ""
  },
  {
      "preset": "prsaufzebene2",
      "starttag": "<li class=\"ebene2\">",
      "endtag": "</li>",
      "br_remove": "yes",
      "note": ""
  },
  {
      "preset": "prsaufzebene3",
      "starttag": "<li class=\"ebene3\">",
      "endtag": "</li>",
      "br_remove": "yes",
      "note": ""
  },
  {
      "preset": "prsaufzebene4",
      "starttag": "<li class=\"ebene4\">",
      "endtag": "</li>",
      "br_remove": "yes",
      "note": ""
  },
  {
      "preset": "prsaufzebene5",
      "starttag": "<li class=\"ebene5\">",
      "endtag": "</li>",
      "br_remove": "yes",
      "note": ""
  },
  {
      "preset": "prsaufzebene6",
      "starttag": "<li class=\"ebene6\">",
      "endtag": "</li>",
      "br_remove": "yes",
      "note": ""
  }
]
  router: any;
  docTyp: any;

  constructor(
    private el: ElementRef,
  ) {}

  ngOnInit(): void {

    this.docTyp = this.document['_source']['docTyp']['id'];
    console.log( this.docTyp);

      // console.log(this.docTyp)
      if (this.document['_source'] && this.document['_source'][`${this.docTyp}_html`]) {
        this.addingBreaks(this.document['_source'][`${this.docTyp}_html`]);
      }
   
  }

  ngAfterContentInit() {
    console.log(this.document);
   
  


    if (this.document['_source']['notes']) {
      this.document['_source']['notes'].forEach(item  => {

        if (item['categoryID'] && item['categoryID'] === '3') {
            this.sachKommentar.push(
                item
            );
        }

        if (item['categoryID'] && item['categoryID'] === '2') {
          this.textKommentar.push(
                  item
              );
        }
      });
    }

   


    console.log(this.sachKommentar);
    console.log(this.textKommentar);
  }


  addingBreaks(html) {
        // html-Text aus Dokument finden
        let htmltext = html;
        console.log(htmltext);
        let newhtml = document.createElement("div");
    newhtml.classList.add('newhtml');
    // die br werden in die spans eingebunden => alle br-Tags werden zu <span class="br">
    newhtml.innerHTML = htmltext.replaceAll('<br>', '<span class=\"br\"></span>');
    // presets kommen aus Strukur aus Datei presets.js.
    for (let i = 0; i < this.presets.length; i++) {
        let preset = this.presets[i].preset;
        let starttag = this.presets[i].starttag;
        let endtag = this.presets[i].endtag;
        let broption = this.presets[i].br_remove;
        if (preset == "-replacepreset") {
            htmltext = newhtml.innerHTML;
            newhtml.innerHTML = htmltext.replaceAll(starttag,endtag);
            continue;
        };
        let spanelement = newhtml.getElementsByTagName("span");
        let status = "closed";
        let jumpspans = 0;
        let supelement = -1;
        // liest alle span-Tags aus und geht diese durch.
        for (let ii = 0; ii < spanelement.length; ii++) {
            let newtxt, newelement;
            let check = supelement + jumpspans;
            // wenn in der Überschrift ein <br> vorkommen sollte, so darf diese Tag nicht berücksichtigt wird => alle br innerhalb einer Überschrift werden gezählt und diese spans werden dann übersprungen.
            if ((ii > check)) {
                jumpspans = 0;
                supelement = ii;
                let clist = spanelement[ii].classList;
                let origintxt;
              
                if (clist.contains(preset)) {
                    origintxt = "<data>" + spanelement[ii].outerHTML + "</data>";
                    if (status == "closed") {
                        status = "open";
                        newtxt = starttag + spanelement[ii].outerHTML;
                        spanelement[ii].outerHTML = origintxt;
                        newelement = newhtml.innerHTML.replace(origintxt, newtxt);
                        newhtml.innerHTML = newelement;
                    }; //braucht kein open, da keine Änderung, wenn Status offen => man muss ein span finden, das class=br hat!
                    let innerdom = document.createElement("div");
                    innerdom.innerHTML = spanelement[ii].innerHTML;
                    let innerbr = innerdom.getElementsByTagName("span");
                    if (innerbr.length) {
                        jumpspans = innerbr.length;
                    };
                } else if ((status == "open") && ((spanelement[ii].className == "br") || (spanelement[ii].className == "br_remove")) && (broption == "yes")) {
                    spanelement[ii].className = "br_remove";
                    origintxt = "<data>" + spanelement[ii].outerHTML + "</data>";
                    newtxt = endtag + spanelement[ii].outerHTML;
                    spanelement[ii].outerHTML = origintxt;
                    newelement = newhtml.innerHTML.replace(origintxt, newtxt);
                    newhtml.innerHTML = newelement;
                    status = "closed";
                } else if ((status == "open") && (!clist.contains(preset))) {
                    origintxt = "<data>" + spanelement[ii].outerHTML + "</data>";
                    newtxt = endtag + spanelement[ii].outerHTML;
                    spanelement[ii].outerHTML = origintxt;
                    newelement = newhtml.innerHTML.replace(origintxt, newtxt);
                    newhtml.innerHTML = newelement;
                    if (broption == "yes") {
                        status = "nextbrremove";
                    } else {
                        status = "closed";
                    };
                } else if ((status == "nextbrremove") && ((spanelement[ii].className == "br") || (spanelement[ii].className == "br_remove"))) {
                    spanelement[ii].className = "br_remove";
                    status = "closed";
                } else {
                    console.log("Keine Änderung nötig.");
                };
            } else {
                console.log("Element war innerhalb eines presets!");
            };
        
        };
    };
        this.document['_source'][`${this.docTyp}_html`] = newhtml.innerHTML;
  }


  ngAfterViewInit() {

  


    this.sachKommentar.forEach((item, index) => {
      console.log(index);
      let id = item.ID;
      

      var testarray = document.getElementsByClassName('notice-' + id);
      console.log(testarray);
      for(var i = 0; i < testarray.length; i++)
      {
          console.log(testarray[i]);
          testarray[i].innerHTML = '<a href="#notice-' + id + '">' + testarray[i].innerHTML + '<sup class="sup">' + (index + 1) + '</sup></a>';
          testarray[i].className += "highlighted";
      }
    });



  }

  toDocument(id) {
    this.router.navigate(['/document/' + id])
    // .then(() => {
    //   window.location.reload();
    // });
  }


  public highlightNotes(event: any, indexItem: any, prefix: string, className: string) {
    let id = indexItem.ID;
    if (indexItem.selected === undefined) {
      indexItem.selected = true;
    } else {
      indexItem.selected = !indexItem.selected;
    }
    let selected = indexItem.selected;

    // if (this.highlightLinks) {
    let nativeEl: HTMLElement = this.el.nativeElement;
    let spans = nativeEl.getElementsByClassName(prefix + id);
    let currSpan;
    // keep track if scrolled, to scroll into view
    let scrolled = false;
    for (let i = 0; i < spans.length; i++) {
      currSpan = spans.item(i);
      // currSpan.scrollIntoView();
      if (selected) {
        currSpan.classList.add(className);
        if (!scrolled) {
          currSpan.scrollIntoView();
          scrolled = true;
        }
      } else {
        currSpan.classList.remove(className);
      }
    }
    // }
  }

  public stripTags(content: string) {
    content = content.replace(/<\/?.*?>/g, '');
    return content;
  }

}
