import { NgModule } from '@angular/core';
import { PlaceholderDirective } from './utilities/placeholder.directive';
import { ConverterViewComponent } from './converter-view/converter-view.component';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../../app-routing.module';
import { ElementComponent } from './element/element.component';
import { SubcontentComponent } from './subcontent/subcontent.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
  declarations: [
    ConverterViewComponent,
    ElementComponent,
    SubcontentComponent,
    PlaceholderDirective
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    MatProgressSpinnerModule
  ],
  exports: [
    ConverterViewComponent,
    AppRoutingModule,
  ],
  providers: [
    PlaceholderDirective
  ]
})
export class ConverterModule { }
